
import {
  CustomizableUIComponent,
  CustomizableUiKpiDetail,
} from '@/api/customizable-uis/customizable-ui.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';
import HorizontalBarChart from '@/components/HorizontalBarChart.vue';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import {
  KpiValue,
  KpiValueResult,
} from '@/api/kpi-values/kpi-value-result.model';
import { User } from '@/api/users/user.model';
import KpiDetailModal from '../KpiDetailModal.vue';
import { namespace } from 'vuex-class';
import _ from 'lodash';
import { Team } from '@/api/teams/team.model';
import { DateTime } from 'luxon';

const kpiValuesService = new KpiValuesService();
const customizableUIsModule = namespace('customizableUIs');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    HorizontalBarChart,
    KpiDetailModal,
  },
})
export default class KpiComparisonCard extends Vue {
  isLoading = false;
  showKpiDetail = false;
  kpiIndex = 0;
  userIndex = 0;

  kpiValuesResults: KpiValueResult[] = [];

  removedUsers: User[] = [];

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop()
  company!: string;

  @Prop()
  teams!: Team[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @customizableUIsModule.Getter('kpiDetails')
  kpiDetails!: CustomizableUiKpiDetail[];

  get title() {
    return this.uiComponent?.title;
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get values() {
    if (!this.kpiValuesResults) {
      return [];
    }
    return this.kpiValuesResults.map((kvr) => {
      return {
        label: this.$t(kvr.kpi_values[0]?.kpi_id),
        values: kvr.kpi_values.map((kv) => kv.value),
      };
    });
  }

  get labels() {
    return this.workingEmployees.map((e) => e.name);
  }

  get employeeHoodyIds() {
    return this.workingEmployees?.map((e) => e.hoodyId);
  }

  get kpi() {
    return this.uiComponent.kpis[this.kpiIndex];
  }

  get kpiValue() {
    return this.kpiValuesResults[this.kpiIndex]?.kpi_values[this.userIndex];
  }

  get user() {
    return this.employees[this.userIndex];
  }

  get workingEmployees() {
    return this.removeUsers();
  }

  get kpiDetail() {
    if (!this.kpiDetails || this.kpiDetails.length === 0) {
      return null;
    }
    return this.kpiDetails.find((kd) => kd.kpi === this.kpi);
  }

  checkIfUserExistsInDeletedUsers(user: User): boolean {
    if (!this.removedUsers || this.removedUsers.length === 0) return false;
    this.removedUsers.forEach((client) => {
      if (client.hoodyId === user.hoodyId) return true;
    });
  }

  checkForDuplicates(obj: any, list: any[]) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] === obj) return true;
    }
    return false;
  }

  removeUsers() {
    let newEmployees: User[] = _.cloneDeep(this.employees);
    if (!this.teams || this.teams.length === 0) return newEmployees;

    const team: Team = this.teams[0];
    team.members.forEach((member) => {
      if (!member.until) return;
      const start = DateTime.fromISO(this.dateRange.start);
      const until = DateTime.fromISO(member.until);
      if (start > until) {
        const index = newEmployees.findIndex(
          (e) => e.hoodyUser.id === member.hoodyUser,
        );
        const user = newEmployees[index];
        const isDuplicate = this.checkIfUserExistsInDeletedUsers(user);
        if (!isDuplicate) this.removedUsers.push(user);

        // remove user from employees list
        newEmployees.splice(index, 1);
      } else {
        if (this.removedUsers && this.removedUsers.length > 0) {
          this.removedUsers.forEach((user) => {
            const isDuplicate = this.checkForDuplicates(user, newEmployees);
            if (!isDuplicate) newEmployees.push(user);
          });
        }
      }
    });
    newEmployees = _.uniqBy(newEmployees, (e) => e.hoodyId);
    return newEmployees;
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  onShowKpiDetail(indexes: { kpiIndex: number; userIndex: number }) {
    this.kpiIndex = indexes.kpiIndex;
    this.userIndex = indexes.userIndex;
    this.showKpiDetail = true;
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.kpiValuesResults = await kpiValuesService.fetchKpiValuesForComponent(
        this.uiComponent,
        this.dateRange,
        [],
        this.employeeHoodyIds,
        this.managers,
      );
    } finally {
      this.isLoading = false;
    }
  }
}
