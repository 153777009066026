import TeamsService from '@/api/teams/teams.service';
import { Team } from '@/api/teams/team.model';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { User } from '@/api/users/user.model';

const teamsService = new TeamsService();

interface TeamState {
  current: Team;
  all: Team[];
  managedUsers: User[];
}

const state: TeamState = {
  current: new Team(),
  all: [],
  managedUsers: [],
};

const getters = {
  current: (state: TeamState) => {
    return state.current;
  },
  all: (state: TeamState) => {
    return state.all;
  },
  managedUsers: (state: TeamState) => {
    return state.managedUsers;
  },
};

const mutations = {
  setCurrent(state: TeamState, team: Team) {
    Vue.set(state, 'current', team);
  },
  setManagedUsers(state: TeamState, users: User[]) {
    Vue.set(state, 'managedUsers', users);
  },
  setAll(state: TeamState, teams: Team[]) {
    Vue.set(state, 'all', teams);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Team[]> {
    const teams = await teamsService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', teams);
    return teams;
  },
  async fetchById(context: any, id: string): Promise<Team> {
    const team = await teamsService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', team);
    return team;
  },
  async create(context: any, payload: Team): Promise<Team> {
    const team = await teamsService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', team);
    return team;
  },
  async updateById(context: any, payload: Team): Promise<Team> {
    const team = await teamsService.update(payload._id, payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', team);
    return team;
  },
  async deleteById(context: any, id: string): Promise<Team> {
    const team = await teamsService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', team);
    return team;
  },
  async fetchManagedUsers(context: any): Promise<User[]> {
    const users = await teamsService.fetchManagedUsers().catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setManagedUsers', users);
    return users;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
