
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '../api/users/user.model';
import { NavItem } from '../shared/types/nav-item.class';
import { Team } from '@/api/teams/team.model';
import { Filter } from '@/shared/types/filter.class';
import {
  CustomizableUI,
  CustomizableUIComponent,
} from '@/api/customizable-uis/customizable-ui.model';

const authModule = namespace('auth');
const usersModule = namespace('users');
const teamsModule = namespace('teams');
const customizableUIsModule = namespace('customizableUIs');

@Component({})
export default class SideMenu extends Vue {
  showTeamSelectModal = false;

  @customizableUIsModule.Getter('current')
  ui!: CustomizableUI;

  navItemsDashboard: NavItem[] = [
    new NavItem(
      this.$t('components.sideMenu.generalDashboard').toString(),
      'GeneralDashboard',
      'chart-simple',
      'GeneralDashboard',
    ),
    new NavItem(
      this.$t('components.sideMenu.teamDashboard').toString(),
      'TeamDashboard',
      'chart-line',
      'TeamDashboard',
    ),
    new NavItem(
      this.$t('components.sideMenu.employeeDashboard').toString(),
      'EmployeeDashboard',
      'user-friends',
      'EmployeeDashboard',
    ),
  ];

  navItemsAnalysis: NavItem[] = [
    new NavItem(
      this.$t('components.sideMenu.benchmark').toString(),
      'BenchmarkDashboard',
      'chart-bar',
      '',
    ),
  ];

  navItemsAdmin: NavItem[] = [
    // new NavItem(
    //   this.$t('components.sideMenu.target').toString(),
    //   'Setup',
    //   'bullseye',
    //   'Setup',
    // ),
    new NavItem(
      this.$t('components.sideMenu.companies').toString(),
      'Companies',
      'building',
      'Companies',
    ),
    new NavItem(
      this.$t('components.sideMenu.integrations').toString(),
      'Integrations',
      'arrows-alt-h',
      'Integration',
    ),
    new NavItem(
      this.$t('components.sideMenu.integrationRuns').toString(),
      'IntegrationRuns',
      'clock',
      'IntegrationRuns',
    ),
    // new NavItem(
    //   this.$t('components.sideMenu.setup').toString(),
    //   'Setup',
    //   'cogs',
    //   'Setup',
    // ),
    new NavItem(
      this.$t('components.sideMenu.users').toString(),
      'Users',
      'user-friends',
      'Users',
    ),
    new NavItem(
      this.$t('components.sideMenu.translations').toString(),
      'Translations',
      'list',
      'Translations',
    ),
    new NavItem(
      this.$t('components.sideMenu.login-as').toString(),
      'LoginAs',
      'user-ninja',
      'LoginAs',
    ),
  ];

  get currentRoute() {
    return this.$route.name;
  }

  get thumbUri() {
    return this.user?.hoodyUser?.thumbUri;
  }

  get accessibleNavItemsDashboard() {
    return this.getAccessibleNavItemsOnly(this.navItemsDashboard);
  }

  get accessibleNavItemsAnalysis() {
    return this.getAccessibleNavItemsOnly(this.navItemsAnalysis);
  }

  get accessibleNavItemsAdmin() {
    return this.getAccessibleNavItemsOnly(this.navItemsAdmin);
  }

  get name() {
    return this.user?.name;
  }

  get userTitle() {
    return this.user?.functionTitle;
  }

  get hasTeams() {
    if (this.user.isSuperAdmin) return false;
    return this.teams && this.teams.length > 1;
  }

  @usersModule.Getter('loggedInUser')
  user!: User;

  @teamsModule.Getter('all')
  teams!: Team[];

  @teamsModule.Action('fetchAll')
  fetchAll!: () => Promise<Team[]>;

  @teamsModule.Getter('current')
  selectedTeam!: any;

  @authModule.Mutation('logout')
  clearData!: () => void;

  @teamsModule.Mutation('setCurrent')
  setSelectedTeam!: (team?: any) => void;

  @usersModule.Mutation('clearLoggedInUser')
  clearLoggedInUser!: () => void;

  get selectedTeamName() {
    return this.selectedTeam?.name || 'None';
  }

  @Watch('user', { deep: true, immediate: true })
  onUserChanged() {
    this.$i18n.locale = this.user.hoodyUser?.language;
  }

  @Watch('teams', { deep: true })
  onTeamChanged() {
    if (this.selectedTeam && this.selectedTeam.company === undefined) {
      if (this.teams && this.teams.length > 0) {
        this.selectTeam(this.teams[0]);
      }
    }
    return this.selectedTeam;
  }

  getAccessibleNavItemsOnly(navItems: NavItem[]) {
    const ret = [];
    for (const item of navItems) {
      if (this.canAccesItem(item.subject)) {
        if (item.route === 'BenchmarkDashboard') {
          if (this.ui.showBenchmarkNav) {
            ret.push(item);
          }
        } else {
          ret.push(item);
        }
      }
    }
    return ret;
  }

  canAccesItem(subject?: string) {
    if (!subject) {
      return true;
    }
    return this.$can('read', subject);
  }

  openTeamSelect() {
    this.showTeamSelectModal = true;
  }

  selectTeam(team: any) {
    this.setSelectedTeam(team);
    this.showTeamSelectModal = false;
  }

  logout() {
    this.clearData();
    this.clearLoggedInUser();
    this.$router.push({ name: 'Login' });
  }

  created() {
    this.fetchAll();
    if (this.hasTeams && !this.selectedTeam) {
      this.showTeamSelectModal = true;
    }
  }
}
