import { DateRange } from '@/components/DateRangeSelect.vue';
import axios from '@/plugins/axios';
import { DateUtil } from '@/shared/util/date.util';
import { DateTime } from 'luxon';
import { SalesFunnel } from './sales-funnel.model';
import { WeightedPipeline } from './weighted-pipeline.model';

export default class KpiVisualsService {
  get endpoint() {
    return 'kpi-visuals';
  }

  async fetchWeightedPipeline(dateRange: DateRange, pipeline?: string) {
    const start = DateUtil.fromISOToISO12MonthsAgo(dateRange.stop);
    const stop = DateUtil.removeTimezoneFromISO(dateRange.stop);
    const response = await axios.get<WeightedPipeline>(
      `${this.endpoint}/weighted-pipeline`,
      {
        params: {
          start,
          stop,
          pipeline: pipeline,
        },
      },
    );

    return response.data;
  }

  async fetchSalesFunnel(
    dateRange: DateRange,
    teams?: string[],
    users?: string[],
    managers?: string[],
    pipeline?: string,
  ) {
    const start = DateUtil.fromISOToISO12MonthsAgo(dateRange.start);
    const stop = DateUtil.removeTimezoneFromISO(dateRange.stop);
    const response = await axios.get<SalesFunnel>(
      `${this.endpoint}/sales-funnel`,
      {
        params: {
          start,
          stop,
          team: teams,
          user: users,
          manager: managers,
          pipeline,
        },
      },
    );

    return response.data;
  }

  async fetchSalesFunnelIndividual(
    dateRange: DateRange,
    users?: string,
    pipeline?: string,
  ) {
    const start = DateUtil.fromISOToISO12MonthsAgo(dateRange.start);
    const stop = DateUtil.removeTimezoneFromISO(dateRange.stop);
    const response = await axios.get<SalesFunnel>(
      `${this.endpoint}/sales-funnel`,
      {
        params: {
          start,
          stop,
          user: users,
          pipeline,
        },
      },
    );

    return response.data;
  }

  private calculateStartDate(dateRange: DateRange, isYTD = false): Date {
    if (isYTD) {
      return DateTime.now().startOf('year').toJSDate();
    }
    return DateTime.now().startOf('week').toJSDate();
  }

  private calculateStopDate(dateRange: DateRange, isYTD = false): Date {
    if (isYTD) {
      return DateTime.now().endOf('year').toJSDate();
    }
    return DateTime.now().endOf('week').toJSDate();
  }
}
