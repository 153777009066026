
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';

import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { User } from '@/api/users/user.model';
import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import KpiValueBadgeColumn from '../KpiValueBadgeColumn.vue';
import EmployeePerformanceCardDateHeader from '../EmployeePerformanceCardDateHeader.vue';
import { DateUtil } from '@/shared/util/date.util';
import { namespace } from 'vuex-class';
import { Filter } from '@/shared/types/filter.class';
import moment from 'moment';
import { formatGeneralImpression } from '@/shared/util/print-column.util';
import _ from 'lodash';

const kpiValuesService = new KpiValuesService();

const activitiesModule = namespace('hoodyActivities');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { KpiValueBadgeColumn, EmployeePerformanceCardDateHeader },
})
export default class EmployeePerformanceCard extends Vue {
  isLoading = true;
  employeeResults: KpiValueResult[][] = [];

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @activitiesModule.Getter('submissions')
  submissions!: any[];

  @activitiesModule.Action('getSurveySubmissionByUser')
  getSurveySubmissionByUser!: (filter?: Filter) => Promise<any[]>;

  get title() {
    return this.uiComponent?.title;
  }

  get kpis() {
    // return ['kpi1', 'kpi2', 'kpi3', 'kpi4'];
    return this.uiComponent?.kpis;
  }

  get firstEmployee() {
    return this.employees[0];
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get values() {
    return this.employeeResults;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e?.hoodyId);
  }

  get dates() {
    const dates = [];
    for (let i = 0; i < 4; ++i) {
      const x = DateUtil.fromISOSubtractDurationIntervalEndOfInterval(
        this.dateRange.stop,
        i,
        this.dateRange.interval,
      );
      dates.push(x);
    }
    return dates.reverse();
  }

  get interval() {
    return this.dateRange.interval;
  }

  @Watch('dateRange', { immediate: true, deep: true })
  async onDateRangeChanged() {
    await this.loadData();
  }

  @Watch('employees', { deep: true })
  onEmployeeChanged() {
    this.loadData();
  }

  getSubmissionForWindow(questionId: number, date: string) {
    const startOfWindow = moment(date).startOf(
      this.interval === 'week' ? 'isoWeek' : this.interval,
    );
    const endOfWindow = moment(date).endOf(
      this.interval === 'week' ? 'isoWeek' : this.interval,
    );
    if (this.submissions) {
      const filteredSubs = this.submissions['items'].filter((sub) => {
        return moment(moment(sub.date).toISOString()).isBetween(
          startOfWindow.toISOString(),
          endOfWindow.toISOString(),
        );
      });
      if (filteredSubs.length === 0) {
        return 'No Answer';
      }
      const lastSub = filteredSubs[filteredSubs.length - 1];
      const question = lastSub.questions.find(
        (q) => parseInt(q.questionId) === questionId,
      );
      const shouldNotFormatAnswer =
        question?.type !== 'multiple_choice_single_selection';
      const answer = question?.answers?.find((a) => a.userAnswered);
      const answerValue = answer?.text || answer?.userInput;

      return shouldNotFormatAnswer
        ? answerValue
        : formatGeneralImpression(answerValue);
    }
  }

  getKpiValue(kpi: string, intervalCount: number, date: any = null) {
    if (kpi.includes(';')) {
      return this.getSubmissionForWindow(Number(kpi.split(';')[1]), date);
    }
    const intervalResults = this.employeeResults[intervalCount];
    if (!intervalResults) {
      return;
    }
    for (const kpiValueResult of intervalResults) {
      const kpiValue = kpiValueResult.kpi_values.find(
        (kv) => kv.kpi_id === kpi,
      );
      if (kpiValue) {
        return kpiValue;
      }
    }
  }

  async loadData() {
    this.isLoading = true;
    this.employeeResults = [];
    try {
      await this.getSurveySubmissionByUser(
        new Filter({ 'user.id': this.employeeHoodyIds[0] }),
      );
      const newUiComponent = _.clone(this.uiComponent);
      newUiComponent.kpis = newUiComponent.kpis.filter((item) => {
        if (!item.includes(';')) return item;
      });

      for (let i = 3; i >= 0; --i) {
        const start = DateUtil.fromISOSubtractDurationIntervalStartOfInterval(
          this.dateRange.start,
          i,
          this.dateRange.interval,
        );
        const stop = DateUtil.fromISOSubtractDurationIntervalEndOfInterval(
          this.dateRange.stop,
          i,
          this.dateRange.interval,
        );
        // const stop = DateUtil.fromISOSubtractIntervals(this.dateRange.stop, i);

        const kpiData =
          await kpiValuesService.fetchKpiValuesForComponentWithStartStop(
            newUiComponent,
            start,
            stop,
            this.teams,
            this.employeeHoodyIds,
            this.managers,
          );
        this.employeeResults.push(kpiData);
      }
    } finally {
      this.isLoading = false;
    }
  }
}
