import { BaseModel } from '@/shared/types/base.model';
import { HoodyCompany } from '@/api/hoody-companies/hoody-company.model';

export class Company extends BaseModel {
  constructor(
    public name?: string,
    public hoodyId: string = '',
    public hoodyCompany = new HoodyCompany(),
  ) {
    super();
  }
}
