import axios from '@/plugins/axios';

export default class HoodyActivitiesService {
  get endpoint() {
    return 'activities';
  }

  async fetchByUser(user: string, to: Date, max = 10): Promise<any[]> {
    const response = await axios.get<any[]>(`${this.endpoint}/${user}`, {
      params: {
        max,
        to,
      },
    });
    return response.data;
  }
}
