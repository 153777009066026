import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { Nudge } from './nudge.model';

export default class NudgesService extends BaseService<Nudge> {
  get endpoint() {
    return 'nudges';
  }
  async addConfig(payload: any): Promise<any> {
    const response = await axios.post<any>('nudges/config', payload);
    return response.data;
  }
}
