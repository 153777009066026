import { AppConfig } from '@/config';
import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { User } from './user.model';

export default class UsersService extends BaseService<User> {
  get endpoint() {
    return `/users`;
  }

  async fetchByHoodyId(id: string): Promise<User> {
    const response = await axios.get<User>(
      `${this.endpoint}/from-hoody-id/${id}`,
    );
    return response.data;
  }

  async reloadFromHoody(id: string): Promise<User> {
    const response = await axios.post<User>(
      `${this.endpoint}/${id}/reload-from-hoody`,
    );
    return response.data;
  }
}
