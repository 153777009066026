/* eslint-disable */
export default {
  name: '{{{name}}}',
  type: 'SUGAR_CRM',
  auth: {
    endpoint: '{{{baseURL}}}/rest/v11/oauth2/token',
    body: {
      grant_type: 'password',
      client_id: '{{{clientId}}}',
      client_secret: '{{{clientSecret}}}',
      username: '{{{username}}}',
      password: '{{{password}}}',
      platform: 'base',
    },
    tokenField: 'access_token',
  },
  endpoints: [
    {
      type: 'CONTACT',
      idField: 'id',
      method: 'POST',
      url: '{{{baseURL}}}/rest/v11/Contacts/filter',
      filter: [],
      mapping: [],
    },
    {
      type: 'TASK',
      idField: 'id',
      method: 'POST',
      url: '{{{baseURL}}}/rest/v11/Tasks/filter',
      filter: [],
      mapping: [],
    },
    {
      type: 'OPPORTUNITY',
      idField: 'id',
      method: 'POST',
      url: '{{{baseURL}}}/rest/v11/Opportunities/filter',
      filter: [],
      mapping: [],
    },
  ],
};
