import { BaseModel } from '@/shared/types/base.model';

export class TeamMember {
  constructor(
    public hoodyUser?: string,
    public from?: string,
    public until?: string,
  ) {}
}

export class Team extends BaseModel {
  constructor(
    public name?: string,
    public company?: string,
    public managers: TeamMember[] = [],
    public members: TeamMember[] = [],
    public pipeline_id?: string,
  ) {
    super();
  }
}
