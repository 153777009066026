
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Filler,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

ChartJS.register(LineElement, LinearScale, CategoryScale, PointElement, Filler);

@Component({
  components: {
    LineChartGenerator,
  },
})
export default class SparklineChart extends Vue {
  @Prop()
  values!: number[];

  get chartData(): ChartData {
    return {
      labels: this.values,
      datasets: [
        {
          fill: true,
          data: this.values,
        },
      ],
    };
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    elements: {
      line: {
        fill: true,
        borderWidth: 2,
        borderColor: '#2C7BE5',
        backgroundColor: '#2C7BE51A',
      },
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
}
