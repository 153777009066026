
import {
  CustomizableUIComponent,
  CustomizableUiKpiDetail,
} from '@/api/customizable-uis/customizable-ui.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { User } from '@/api/users/user.model';
import KpiDetailModal from '../KpiDetailModal.vue';
import { namespace } from 'vuex-class';
import ProgressBarChart from '@/components/progressBarChart.vue';
import { DateRangeEnum } from '@/components/DateRangeSelect.vue';
import { DateTime } from 'luxon';

const kpiValuesService = new KpiValuesService();
const customizableUIsModule = namespace('customizableUIs');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    ProgressBarChart,
    KpiDetailModal,
  },
})
export default class TargetProgressCard extends Vue {
  isLoading = false;
  showKpiDetail = false;
  kpiIndex = 0;
  userIndex = 0;
  targets = [];
  restValue = [];

  currentTargets = [];
  currentRemainigs = [];

  kpiValuesResults: KpiValueResult;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @customizableUIsModule.Getter('kpiDetails')
  kpiDetails!: CustomizableUiKpiDetail[];

  get title() {
    return this.uiComponent?.title;
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get kpiValues() {
    return this.kpiValuesResults.kpi_values;
  }

  getTargetForHoodyId(hoodyId: string) {
    if (this.uiComponent.isYTD) {
      const i = 0;
    }
  }

  sumOfValuesAndTargetsForHoodyId(hoodyId: string) {
    let sum = 0;
    let dataByHoodyId = [];
    let target = 0;
    if (this.kpiValues.length === 1) {
      dataByHoodyId = this.kpiValuesResults.kpi_values[0].info.filter(
        (e: any) => e.user[0] === hoodyId,
      );
    }
    dataByHoodyId.map((obj: any) => {
      sum = sum + obj.value;
    });
    if (this.uiComponent.isYTD) {
      target = dataByHoodyId[0].target * 52;
    } else if (this.dateRange.interval === DateRangeEnum.Month) {
      target = dataByHoodyId[0].target * 4;
    } else if (this.dateRange.interval === DateRangeEnum.Quarter) {
      target = dataByHoodyId[0].target * 12;
    } else {
      target = dataByHoodyId[0].target;
    }

    return {
      target: target,
      value: sum,
    };
  }

  calculateActualTarget(target: number, start: string, stop: string) {
    const newStart = DateTime.fromISO(start);
    const newStop = DateTime.fromISO(stop);
    const diff = newStop.diff(newStart, 'days');
    const oneDayTarget = target / (diff.toObject().days + 2);
    return oneDayTarget * 365;
  }

  get newValues() {
    if (!this.kpiValuesResults) {
      return [];
    }
    const currentValues = [];
    this.kpiValuesResults.kpi_values.map((item) => {
      currentValues.push(item.value);
      const actualTarget = this.calculateActualTarget(
        item.target,
        this.kpiValuesResults.start,
        this.kpiValuesResults.stop,
      );
      const target = parseFloat(actualTarget.toFixed(2));
      if (item.value < target) {
        this.currentRemainigs.push(target - item.value);
      } else {
        this.currentRemainigs.push(0);
      }
      this.currentTargets.push(target);
    });
    return currentValues;
  }

  newTarget(currentValue: number, target: number) {
    let newTarget = target;
    let i = 2;
    do {
      newTarget = target * i;
      i++;
    } while (newTarget < currentValue);
    return newTarget;
  }

  get labels() {
    return this.employees.map((e) => e.name);
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  get kpi() {
    return this.uiComponent.kpis[this.kpiIndex];
  }

  get user() {
    return this.employees[this.userIndex];
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  onShowKpiDetail(indexes: { kpiIndex: number; userIndex: number }) {
    this.kpiIndex = indexes.kpiIndex;
    this.userIndex = indexes.userIndex;
    this.showKpiDetail = true;
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.kpiValuesResults = await kpiValuesService.fetchKpiValuesForComponent(
        this.uiComponent,
        this.dateRange,
        [],
        this.employeeHoodyIds,
        this.managers,
      );
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    await this.loadData();
  }
}
