
import { CustomizableUiKpiDetail } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValue } from '@/api/kpi-values/kpi-value-result.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import { User } from '@/api/users/user.model';
import KpiDetailModal from './KpiDetailModal.vue';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

const customizableUIsModule = namespace('customizableUIs');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { KpiDetailModal } })
export default class KpiValueBadgeColumn extends Vue {
  showKpiDetail = false;
  comparedVariant = '';
  uuidv4 = uuidv4();

  @Prop()
  kpiValue!: KpiValue | string;

  @Prop()
  isCompare!: boolean;

  @Prop()
  compareValue!: KpiValue;

  @Prop()
  kpi!: string;

  @Prop()
  user!: User;

  @customizableUIsModule.Getter('kpiDetails')
  kpiDetails!: CustomizableUiKpiDetail[];

  get classes() {
    const softColor = this.softColor;
    const classes = `badge-fixed py-2 ${softColor}`;
    if (this.hasKpiDetail) {
      return `${classes}  cursor-pointer`;
    }
    return classes;
  }

  get kpiDetail() {
    if (!this.kpiDetails || this.kpiDetails.length === 0) {
      return null;
    }
    return this.kpiDetails.find((kd) => kd.kpi === this.kpi);
  }

  get uniqueActivityTypes() {
    if (typeof this.kpiValue === 'string') return [];
    return Array.from(
      new Set(this.kpiValue.info.map((item) => item.activity_type)),
    );
  }

  get calenderInfo() {
    if (typeof this.kpiValue === 'string') return [];
    const x = [];
    const ai = this.uniqueActivityTypes;
    ai.forEach((id) => {
      const objs = [];
      let count = 0;
      if (typeof this.kpiValue === 'string') return [];
      this.kpiValue.info.map((item) => {
        if (item.activity_type === id) {
          const d1 = DateTime.fromISO(item.start);
          const d2 = DateTime.fromISO(item.end);
          const diff = d2.diff(d1, ['minutes']);
          count = count + diff.toObject().minutes;
          objs.push(item);
        }
      });
      const data = {
        type: id,
        amount: objs.length,
        total: count,
      };
      x.push(data);
    });
    return x;
  }

  get hasKpiDetail() {
    if (!this.kpiDetail || !this.kpiDetail.fields?.length) {
      return false;
    }
    return true;
  }

  get comparedValue() {
    const diff = Number(this.value) - this.compareValue.value;
    const decimal = diff / Number(this.value);
    const percentage = decimal * 100;
    this.comparedVariant = isFinite(decimal) ? 'red' : 'green';
    return isFinite(decimal)
      ? Formatter.percentage(percentage)
      : Formatter.percentage(0);
  }

  get valueFormatted() {
    if (typeof this.value === 'string') return this.value;
    if (
      this.kpi === 'e9d98eb0-d8fd-4f62-a3d3-051419dd921b' ||
      this.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17' ||
      this.kpi === '34382753-7f37-4bc3-986e-96332c6bd8a0'
    ) {
      return Formatter.currency(this.value);
    }

    if (this.kpi === 'd5fa71f1-72f7-4a7c-8547-b1cd596377bf') {
      return Math.round(this.value);
    }
    return this.value;
  }

  get valueFormattedTimeBooked() {
    if (typeof this.value === 'string') return this.value;
    if (this.value && this.target) {
      const divide = Number(this.value) / Number(this.target);
      const percentage = divide * 100;
      return `${percentage.toFixed(2)} %`;
    }
    if (this.value === 0) return '0%';

    return this.value;
  }

  get targetFormatted() {
    if (this.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17') {
      return Formatter.currency(this.target);
    }
    return this.target;
  }

  get value() {
    if (typeof this.kpiValue === 'string') {
      return this.kpiValue;
    }
    return this.kpiValue?.value || 0;
  }

  get target() {
    if (typeof this.kpiValue === 'string') {
      return 0;
    }
    return this.kpiValue?.target || 0;
  }

  get variant() {
    if (this.kpi === '3b8f1e23-be3e-4b01-a23d-507b0a2e888d') {
      if (typeof this.kpiValue !== 'string') {
        if (this.kpiValue.value === 0) return 'primary';
        if (this.kpiValue.value < 0) return 'danger';
        return 'primary';
      }
    }
    if (!this.target) {
      return 'soft-dark';
    }
    return this.colorIndicator;
  }

  get softColor() {
    const indicator = this.colorIndicator;
    if (this.kpi === 'd5fa71f1-72f7-4a7c-8547-b1cd596377bf') {
      if (indicator === 'primary') return 'badge-primary';
      if (indicator === 'warning') return 'badge-warning';
      if (indicator === 'danger') return 'badge-danger';
    }
    if (this.kpi === '3b8f1e23-be3e-4b01-a23d-507b0a2e888d') {
      if (typeof this.kpiValue !== 'string') {
        if (this.kpiValue.value === 0) return 'badge-primary';
        if (this.kpiValue.value < 0) return 'badge-danger';
        return 'badge-primary';
      }
    }
    if (indicator === 'primary') return 'badge-soft-primary';
    if (indicator === 'warning') return 'badge-soft-warning';
    return 'badge-soft-danger';
  }

  get colorIndicator() {
    if (typeof this.value !== 'string') {
      const percentage = this.value / this.target;
      if (percentage >= 1) return 'primary';
      if (percentage >= 0.8) return 'warning';
      return 'danger';
    }
    return 'danger';
  }

  onBadgeClicked() {
    if (!this.hasKpiDetail) {
      return;
    }
    this.showKpiDetail = true;
  }
}
