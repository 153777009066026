import axios from '@/plugins/axios';
import { LoginResult } from './login-result.class';
import { Login } from './login.class';
import { AxiosError, AxiosResponse } from 'axios';
import { AppConfig } from '@/config';
import { ResetPassword } from './reset-password.class';

export default class AuthService {
  endpoint = AppConfig.VUE_APP_HOODY_API;

  async login(loginData: Login): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/user/sessions`,
      loginData,
    );
    return response.data;
  }

  async loginAs(username: string): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/user/impersonation?username=${username}`,
    );
    return response.data;
  }

  async validateSession(id: string): Promise<AxiosResponse | undefined> {
    try {
      return await axios.get<LoginResult>(
        `${this.endpoint}/user/sessions/${id}`,
      );
    } catch (e) {
      // Return full response if status == 404
      if (e.response?.status === 404) {
        return e.response;
      } else return undefined;
    }
  }

  async requestResetPassword(email: string): Promise<void> {
    await axios.post(`${this.endpoint}/user/reset/request`, undefined, {
      params: {
        username: email,
      },
    });
  }

  async resetPassword(body: ResetPassword): Promise<void> {
    await axios.post(`${this.endpoint}/user/reset`, body);
  }
}
