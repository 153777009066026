import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { CustomizableUI } from './customizable-ui.model';

export default class CustomizableUIsService extends BaseService<CustomizableUI> {
  get endpoint() {
    return 'customizable-uis';
  }

  async fetchForLoggedInUser(): Promise<CustomizableUI> {
    const response = await axios.get<CustomizableUI>(
      `${this.endpoint}/for-user`,
    );
    return response.data;
  }

  async fetchForCompany(company: string): Promise<CustomizableUI> {
    const response = await axios.get<CustomizableUI>(
      `${this.endpoint}/for-company/${company}`,
    );
    return response.data;
  }
}
