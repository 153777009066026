/* eslint @typescript-eslint/no-var-requires: "off" */

import Vue from 'vue';
import ProgressBarCard from '@/components/dashboard-components/ProgressBarCard.vue';
import ProgressChartCard from '@/components/dashboard-components/ProgressChartCard.vue';
import WeightedPipelineCard from '@/components/dashboard-components/WeightedPipelineCard.vue';
import TopResultsCard from '@/components/dashboard-components/TopResultsCard.vue';
import SalesFunnelCard from '@/components/dashboard-components/SalesFunnelCard.vue';
import TeamPerformanceCard from '@/components/dashboard-components/TeamPerformanceCard.vue';
import KpiComparisonCard from '@/components/dashboard-components/KpiComparisonCard.vue';
import EmployeePerformanceCard from '@/components/dashboard-components/EmployeePerformanceCard.vue';
import KpiComparisonIntervalCard from '@/components/dashboard-components/KpiComparisonIntervalCard.vue';
import CompanyAvgComparisonCard from '@/components/dashboard-components/CompanyAvgComparisonCard.vue';
import TeamAvgComparisonCard from '@/components/dashboard-components/TeamAvgComparisonCard.vue';
import EmployeeProgressionCard from '@/components/dashboard-components/EmployeeProgressionCard.vue';
import CompanyAvgComparisonCardSingleWeek from '@/components/dashboard-components/CompanyAvgComparisonCardSingleWeek.vue';
import TeamAvgComparisonCardSingleWeek from '@/components/dashboard-components/TeamAvgComparisonCardSingleWeek.vue';
import DealSourcePerformanceCard from '@/components/dashboard-components/DealSourcePerformanceCard.vue';
import TargetProgressCard from '@/components/dashboard-components/TargetProgressCard.vue';
import SalesFunnelCardIndividual from '@/components/dashboard-components/SalesFunnelCardIndividual.vue';
import ArrMrrWeightedPipelineCard from '@/components/dashboard-components/ArrMrrWeightedPipelineCard.vue';
import DealSourcePerTeamPerformanceCard from '@/components/dashboard-components/DealSourcePerTeamPerformanceCard.vue';

Vue.component('ProgressBarCard', ProgressBarCard);
Vue.component('ProgressChartCard', ProgressChartCard);
Vue.component('WeightedPipelineCard', WeightedPipelineCard);
Vue.component('TopResultsCard', TopResultsCard);
Vue.component('SalesFunnelCard', SalesFunnelCard);
Vue.component('TeamPerformanceCard', TeamPerformanceCard);
Vue.component('EmployeePerformanceCard', EmployeePerformanceCard);
Vue.component('KpiComparisonCard', KpiComparisonCard);
Vue.component('KpiComparisonIntervalCard', KpiComparisonIntervalCard);
Vue.component('CompanyAvgComparisonCard', CompanyAvgComparisonCard);
Vue.component('TeamAvgComparisonCard', TeamAvgComparisonCard);
Vue.component(
  'CompanyAvgComparisonCardSingleWeek',
  CompanyAvgComparisonCardSingleWeek,
);
Vue.component(
  'TeamAvgComparisonCardSingleWeek',
  TeamAvgComparisonCardSingleWeek,
);
Vue.component('EmployeeProgressionCard', EmployeeProgressionCard);
Vue.component('DealSourcePerformanceCard', DealSourcePerformanceCard);
Vue.component('TargetProgressCard', TargetProgressCard);
Vue.component('SalesFunnelCardIndividual', SalesFunnelCardIndividual);
Vue.component('ArrMrrWeightedPipelineCard', ArrMrrWeightedPipelineCard);
Vue.component(
  'DealSourcePerTeamPerformanceCard',
  DealSourcePerTeamPerformanceCard,
);

export enum UiComponentType {
  ProgressBarCard = 'ProgressBarCard',
  ProgressChartCard = 'ProgressChartCard',
  WeightedPipelineCard = 'WeightedPipelineCard',
  TopResultsCard = 'TopResultsCard',
  SalesFunnelCard = 'SalesFunnelCard',
  TeamPerformanceCard = 'TeamPerformanceCard',
  EmployeePerformanceCard = 'EmployeePerformanceCard',
  KpiComparisonCard = 'KpiComparisonCard',
  KpiComparisonIntervalCard = 'KpiComparisonIntervalCard',
  CompanyAvgComparisonCard = 'CompanyAvgComparisonCard',
  TeamAvgComparisonCard = 'TeamAvgComparisonCard',
  EmployeeProgressionCard = 'EmployeeProgressionCard',
  CompanyAvgComparisonCardSingleWeek = 'CompanyAvgComparisonCardSingleWeek',
  TeamAvgComparisonCardSingleWeek = 'TeamAvgComparisonCardSingleWeek',
  DealSourcePerformanceCard = 'DealSourcePerformanceCard',
  TargetProgressCard = 'TargetProgressCard',
  SalesFunnelCardIndividual = 'SalesFunnelCardIndividual',
  ArrMrrWeightedPipelineCard = 'ArrMrrWeightedPipelineCard',
  DealSourcePerTeamPerformanceCard = 'DealSourcePerTeamPerformanceCard',
}
