import HoodyCompaniesService from '@/api/hoody-companies/hoody-companies.service';
import { HoodyCompany } from '@/api/hoody-companies/hoody-company.model';
import Vue from 'vue';

const hoodyCompaniesService = new HoodyCompaniesService();

interface HoodyCompanyState {
  all: HoodyCompany[];
}

const state: HoodyCompanyState = {
  all: [],
};

const getters = {
  all: (state: HoodyCompanyState) => {
    return state.all;
  },
};

const mutations = {
  setAll(state: HoodyCompanyState, companies: HoodyCompany[]) {
    Vue.set(state, 'all', companies);
  },
};

const actions = {
  async fetchAll(context: any): Promise<HoodyCompany[]> {
    const companies = await hoodyCompaniesService.fetchAll();
    context.commit('setAll', companies);
    return companies;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
