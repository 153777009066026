import { BaseModel } from '@/shared/types/base.model';
import { KpiDetailFieldType } from './kpi-detail-field-type.enum';
import { DealSources } from '@/api/kpi-values/kpi-value-result.model';
import { Pipeline } from '@/api/pipelines/pipeline.model';

export class CustomizableUiKpiDetailField {
  constructor(
    public field?: string,
    public title?: string,
    public type?: KpiDetailFieldType,
    public badgeVariant?: string,
    public formatter?: string,
  ) {}
}

export class CustomizableUiKpiDetail {
  constructor(
    public kpi: string,
    public fields: CustomizableUiKpiDetailField[] = [],
  ) {}
}

export class CustomizableUIComponentSizing {
  constructor(
    public xs?: number,
    public sm?: number,
    public md?: number,
    public lg?: number,
    public xl?: number,
    public xsOffset: number = 0,
    public smOffset: number = 0,
    public mdOffset: number = 0,
    public lgOffset: number = 0,
    public xlOffset: number = 0,
  ) {}
}

export class CustomizableUIComponent {
  constructor(
    public type: string,
    public title?: string,
    public kpi?: string,
    public sizing: CustomizableUIComponentSizing = new CustomizableUIComponentSizing(),
    public isYTD: boolean = false,
    public tooltip?: string,
    public showReason: boolean = false,
    public badgeVariant?: string,
    public kpis?: string[],
    public formatter?: string,
    public numberOfIntervals?: number,
    public compareToLastInterval?: boolean,
    public compareToLastSelectedInterval?: boolean,
    public selectedDealSources?: DealSources[],
    public selectedPipelines?: Pipeline[],
    public isYTDOldSpec?: boolean,
    public showMaxLine?: boolean,
    public maxNumber?: number,
    public maxNumberLabel?: number,
    public showAvgDealLineWeightedPipeline?: boolean,
    public annotation1Active?: boolean,
    public annotation1Value?: number,
    public annotation1Label?: string,
    public annotation1IsAverage?: boolean,
    public annotation2Active?: boolean,
    public annotation2Value?: number,
    public annotation2Label?: string,
    public annotation2IsAverage?: boolean,
    public maxResultsTopCard?: number,
  ) {}
}

export class Annotation {
  constructor(
    public active: boolean,
    public value: number,
    public label: string,
  ) {}
}

export class CustomNudgeKPI {
  constructor(
    public surveyId: string,
    public questionId?: string,
    public title?: number | string,
  ) {}
}

export class CustomizableUI extends BaseModel {
  constructor(
    public company?: string,
    public generalDashboard: CustomizableUIComponent[][] = [],
    public teamDashboard: CustomizableUIComponent[][] = [],
    public employeeDashboard: CustomizableUIComponent[][] = [],
    public employeeReportDashboard: CustomizableUIComponent[][] = [],
    public benchmarkDashboard: CustomizableUIComponent[][] = [],
    public kpiDetails: CustomizableUiKpiDetail[] = [],
    public window: string = 'week',
    public ytdStartMonth: number = 1,
    public showTimelineTab?: boolean,
    public showReportTab?: boolean,
    public showCustomNudges?: boolean,
    public showBenchmarkNav?: boolean,
    public showSalesHealthNav?: boolean,
    public customNudgesAsKpi?: CustomNudgeKPI[],
    public defaultPipelineID?: string,
  ) {
    super();
  }
}
