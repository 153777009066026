/* eslint-disable */
export default {
  name: '{{{name}}}',
  type: 'SALESFORCE',
  auth: {
    endpoint: '{{{baseURL}}}/rest/v11/oauth2/token',
    body: {
      grant_type: 'password',
      client_id: '{{{clientId}}}',
      client_secret: '{{{clientSecret}}}',
      username: '{{{username}}}',
      password: '{{{password}}}',
      platform: 'base',
    },
    tokenField: 'access_token',
  },
  meta: {
    base_url: '{{{baseURL}}}',
    excluded_record_types: [],
  },
};
