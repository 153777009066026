import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import { User } from '@/api/users/user.model';
import AuthService from '@/api/auth/auth.service';
import { Login } from '@/api/auth/login.class';
import { ResetPassword } from '@/api/auth/reset-password.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { Registration } from '@/api/auth/registration.model';
import { i18n } from '../../plugins/i18n.plugin';
import { AxiosError, AxiosResponse } from 'axios';

Vue.use(Vuex);

const authService = new AuthService();

const state = {
  currentLogin: {},
  token: localStorage.getItem('token'),
  rememberMeToken: localStorage.getItem('rememberMeToken'),
  rememberMeSeries: localStorage.getItem('rememberMeSeries'),
  isImpersonating: Boolean(localStorage.getItem('isImpersonating')),
};

const getters = {
  currentLogin: (state: any) => {
    return state.currentLogin;
  },
  token: (state: any) => {
    return state.token;
  },
  rememberMeToken: (state: any) => {
    return state.rememberMeToken;
  },
  rememberMeSeries: (state: any) => {
    return state.rememberMeSeries;
  },
  isImpersonating: (state: any) => {
    return state.isImpersonating;
  },
  logout: (state: any) => {
    delete state.currentLogin;
    delete state.token;
  },
};

const mutations = {
  setCurrentLogin(state: any, user: User) {
    Vue.set(state, 'currentLogin', user);
    localStorage.setItem('currentLogin', JSON.stringify(user));
  },
  setToken(state: any, token: string) {
    localStorage.setItem('token', token);
    Vue.set(state, 'token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  setRememberMeToken(state: any, token: string) {
    localStorage.setItem('rememberMeToken', token);
    Vue.set(state, 'rememberMeToken', token);
  },
  setRememberMeSeries(state: any, series: string) {
    localStorage.setItem('rememberMeSeries', series);
    Vue.set(state, 'rememberMeSeries', series);
  },
  setRememberMe(state: any, rememberMe: { series: string; token: string }) {
    localStorage.setItem('rememberMeToken', rememberMe.token);
    localStorage.setItem('rememberMeSeries', rememberMe.series);
    Vue.set(state, 'rememberMeToken', rememberMe.token);
    Vue.set(state, 'rememberMeSeries', rememberMe.series);
    axios.defaults.headers.common[
      'X-Remember-Me'
    ] = `${rememberMe.series} ${rememberMe.token}`;
  },
  deleteRememberMe(state: any) {
    delete state.rememberMeToken;
    delete state.rememberMeSeries;
    localStorage.removeItem('rememberMeToken');
    localStorage.removeItem('rememberMeSeries');
    delete axios.defaults.headers.common['X-Remember-Me'];
  },
  deleteToken(state: any) {
    delete state.token;
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  },
  deleteCurrentLogin(state: any) {
    delete state.currentLogin;
    localStorage.removeItem('currentLogin');
  },
  setIsImpersonating(state: any, isImpersonating: boolean) {
    state.isImpersonating = isImpersonating;
    localStorage.setItem('isImpersonating', isImpersonating.toString());
  },
  logout(state: any) {
    delete state.currentLogin;
    delete state.token;
    delete state.rememberMeToken;
    delete state.rememberMeSeries;
    delete state.isImpersonating;
    localStorage.removeItem('currentLogin');
    localStorage.removeItem('token');
    localStorage.removeItem('rememberMeToken');
    localStorage.removeItem('rememberMeSeries');
    localStorage.removeItem('isImpersonating');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common['X-Remember-Me'];
  },
};

const processNewToken = (context: any, token: string) => {
  if (token != undefined) {
    context.commit('setToken', token);
  } else {
    context.commit('deleteToken');
  }
};

const getPersistedSession = () => {
  let persistedSession;
  try {
    persistedSession = JSON.parse(localStorage.getItem('currentLogin'));
  } catch (e) {
    persistedSession = {};
  }
  return persistedSession;
};

const processNewRememberMe = (context: any, rememberMe: any) => {
  if (
    rememberMe.rememberMeSeries != undefined &&
    rememberMe.rememberMeToken != undefined
  ) {
    context.commit('setRememberMe', {
      series: rememberMe.rememberMeSeries,
      token: rememberMe.rememberMeToken,
    });
  } else {
    context.commit('deleteRememberMe');
  }
};

const actions = {
  async requestResetPassword(context: any, email: string): Promise<boolean> {
    try {
      await authService.requestResetPassword(email);
      return true;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async resetPassword(
    context: any,
    resetPayload: ResetPassword,
  ): Promise<boolean> {
    try {
      await authService.resetPassword(resetPayload);
      return true;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async login(context: any, login: Login) {
    try {
      const result = await authService.login(login);
      context.commit('setCurrentLogin', result);
      processNewToken(context, result.token);
      processNewRememberMe(context, {
        rememberMeSeries: result.rememberMeSeries,
        rememberMeToken: result.rememberMeToken,
      });
      return result;
    } catch (e) {
      context.commit('logout');
      context.commit(
        'app/addToast',
        new Toast(
          i18n.t('common.ui.errors.requestTimeOut').toString(),
          ToastType.ERROR,
        ),
        {
          root: true,
        },
      );
      return false;
    }
  },
  async loginAs(context: any, username: string) {
    try {
      const result = await authService.loginAs(username);
      context.commit('setCurrentLogin', result);
      context.commit('setIsImpersonating', true);
      processNewToken(context, result.token);
      processNewRememberMe(context, {
        rememberMeSeries: result.rememberMeSeries,
        rememberMeToken: result.rememberMeToken,
      });
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async fetchActiveSession(context: any) {
    if (!context.state.token) {
      return false;
    }
    const result: AxiosResponse = await authService.validateSession(
      context.state.token,
    );
    if (!result) return false;
    // Important to check lowercase header!
    const xRememberMeHeaderSplits = result.headers['x-remember-me']?.split(' ');
    const sessionData = result.data;
    if (sessionData?.userId && xRememberMeHeaderSplits == undefined) {
      context.commit('setCurrentLogin', sessionData);
      processNewToken(context, sessionData.token);
      return result;
    }
    if (xRememberMeHeaderSplits?.length === 3) {
      const newToken = xRememberMeHeaderSplits[0];
      const newRememberMeSeries = xRememberMeHeaderSplits[1];
      const newRememberMeToken = xRememberMeHeaderSplits[2];
      processNewRememberMe(context, {
        rememberMeSeries: newRememberMeSeries,
        rememberMeToken:
          newRememberMeToken !== '__reuse__'
            ? newRememberMeToken
            : state.rememberMeToken,
      });
      processNewToken(context, newToken);
      // Update current session
      context.commit('setCurrentLogin', {
        ...getPersistedSession(),
        rememberMeSeries: newRememberMeSeries,
        rememberMeToken: newRememberMeToken,
        token: newToken,
      });
      return;
    }
    // Load persisted session data in state if everything else fails
    context.commit('setCurrentLogin', getPersistedSession());
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
