/* eslint-disable */
export default {
  name: '{{{name}}}',
  type: 'TEAMLEADER20',
  auth: {
    endpoint: 'https://app.teamleader.eu/api/getUsers.php',
  },
  endpoints: [
    {
      type: 'CONTACT',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/contacts.list',
    },
    {
      type: 'TASK',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/tasks.list',
    },
    {
      type: 'TASK_TYPE',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/workTypes.list',
    },
    {
      type: 'OPPORTUNITY',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/deals.list',
    },
    {
      type: 'USER',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/users.list',
    },
    {
      type: 'COMPANY',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/companies.list',
    },
    {
      type: 'CALL',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'MEETING',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'DEAL_PHASE',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/dealPhases.list',
    },
    {
      type: 'DEAL_SOURCE',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/dealSources.list',
    },
    {
      type: 'QUOTATION',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/quotations.list',
    },
    {
      type: 'AGENDA',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'AGENDA_SCOPED',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'ACTIVITY_TYPES',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/activityTypes.list',
    },
  ],
};
