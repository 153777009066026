import Vue from 'vue';
import Vuex from 'vuex';
import { IntegrationRecord } from '@/api/integration-records/integration-record.model';
import IntegrationRecordsService from '@/api/integration-records/integration-records.service';
import { Filter } from '@/shared/types/filter.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const integrationRecordsService = new IntegrationRecordsService();

const state = {
  current: new IntegrationRecord(),
  all: [],
  allHistorical: [],
  pagination: {},
};

const getters = {
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
  allHistorical: (state: any) => {
    return state.allHistorical;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, record: IntegrationRecord) {
    Vue.set(state, 'current', record);
  },
  setAll(state: any, records: IntegrationRecord[]) {
    Vue.set(state, 'all', records);
  },
  setHistoricalRecords(state: any, records: IntegrationRecord[]) {
    Vue.set(state, 'allHistorical', records);
  },
};

const actions = {
  async fetchById(context: any, id: string) {
    try {
      const record = await integrationRecordsService.fetchOne(id).catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
      context.commit('setCurrent', record);
      return record;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchPaginated(
    context: any,
    payload?: Filter,
  ): Promise<PaginateResult<IntegrationRecord>> {
    const records = await integrationRecordsService
      .fetch(payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setPagination', records);
    context.commit('setAll', records.docs);
    return records;
  },

  async fetchAll(context: any, payload?: Filter): Promise<IntegrationRecord[]> {
    const records = await integrationRecordsService
      .fetchAll(payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setAll', records);
    return records;
  },
  async fetchAllHistoricalRecordsForExternalId(
    context: any,
    payload: { externalId: string; integration: string },
  ): Promise<IntegrationRecord[]> {
    const records = await integrationRecordsService
      .fetchAllHistoricalRecordsForExternalId(
        payload.externalId,
        payload.integration,
      )
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setHistoricalRecords', records);
    return records;
  },
  async delete(context: any, id: string): Promise<IntegrationRecord> {
    try {
      return integrationRecordsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(
    context: any,
    record: IntegrationRecord,
  ): Promise<IntegrationRecord> {
    try {
      return await integrationRecordsService.update(record._id || '', record);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(
    context: any,
    record: IntegrationRecord,
  ): Promise<IntegrationRecord> {
    try {
      const result = await integrationRecordsService.create(record);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
