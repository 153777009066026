
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { KpiTopValueResult } from '@/api/kpi-top-values/kpi-top-value-result.model';
import KpiTopValuesService from '@/api/kpi-top-values/kpi-top-values.service';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';

const kpiTopValuesService = new KpiTopValuesService();

// The @Component decorator indicates the class is a Vue component
@Component({
  computed: {
    Formatter() {
      return Formatter;
    },
  },
})
export default class ProgressCard extends Vue {
  isLoading = false;
  kpiTopValuesResult: KpiTopValueResult = new KpiTopValueResult();
  error: string = null;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  get title() {
    return this.uiComponent?.title;
  }

  get showReason() {
    return this.uiComponent?.showReason;
  }

  get variant() {
    return this.uiComponent?.badgeVariant;
  }

  get items() {
    return this.kpiTopValuesResult.deals;
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  kpiInfoTranslations() {
    if (this.uiComponent.kpi === 'deals-lost') {
      return this.$t('kpi.explain.top-deals-lost');
    }
    if (this.uiComponent.kpi === 'new-leads') {
      return this.$t('kpi.explain.top-new-leads');
    }
    if (this.uiComponent.kpi === 'projected_closing') {
      return this.$t('kpi.explain.top-projected_closing');
    }
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  valueFormatted(value: number) {
    if (this.formatter) {
      return Formatter[this.formatter](this.value(value));
    }
    return value;
  }

  value(value: number) {
    if (value <= 0) return 0;
    return value;
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.kpiTopValuesResult =
        await kpiTopValuesService.fetchKpiValuesForComponent(
          this.uiComponent,
          this.dateRange,
          this.teams,
          this.employeeHoodyIds,
          this.managers,
        );
    } catch (e) {
      this.error = `${e.message} - ${e.config?.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
