import { BaseModel } from '@/shared/types/base.model';

export class IntegrationRecord extends BaseModel {
  constructor(
    public integration?: string,
    public type?: string,
    public externalId?: string,
    public data: any = {},
  ) {
    super();
  }
}
