
import { ColorUtils } from '@/shared/util/color.util';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class DealSourcePerformanceColumn extends Vue {
  @Prop()
  source!: any;

  @Prop()
  index!: number;

  get name() {
    return this.source?.name;
  }

  get color() {
    return ColorUtils.getUniqueColorForIndex(this.index, this.userId);
  }

  get userId() {
    return this.source?._id;
  }
}
