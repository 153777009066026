export class KpiValue {
  constructor(
    public kpi_id?: string,
    public target?: number,
    public value?: number,
    public day?: string,
    public label?: string,
    public info?: any[],
    public user_id?: string,
    public team_id?: string,
    public manager_id?: string,
    public company_id?: string,
    public insights?: any,
  ) {}
}

export class KpiValueResult {
  start: string;
  stop: string;
  kpi_values: KpiValue[] = [];
}

export interface DealSource {
  id: string;
  name: string;
}

export class DealSources {
  sources: DealSource[];
}
