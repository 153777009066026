import PipelinesService from '@/api/pipelines/pipelines.service';
import { Pipeline } from '@/api/pipelines/pipeline.model';
import { Filter } from '@/shared/types/filter.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const pipelinesService = new PipelinesService();

interface PipelineState {
  current: Pipeline;
  all: Pipeline[];
}

const state: PipelineState = {
  current: new Pipeline(),
  all: [],
};

const getters = {
  current: (state: PipelineState) => {
    return state.current;
  },
  all: (state: PipelineState) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: PipelineState, pipeline: Pipeline) {
    Vue.set(state, 'current', pipeline);
  },
  setAll(state: PipelineState, pipelines: Pipeline[]) {
    Vue.set(state, 'all', pipelines);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Pipeline[]> {
    const pipelines = await pipelinesService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', pipelines);
    return pipelines;
  },
  async fetchById(context: any, id: string): Promise<Pipeline> {
    const pipeline = await pipelinesService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', pipeline);
    return pipeline;
  },
  async create(context: any, payload: Pipeline): Promise<Pipeline> {
    const pipeline = await pipelinesService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', pipeline);
    return pipeline;
  },
  async updateById(context: any, payload: Pipeline): Promise<Pipeline> {
    const pipeline = await pipelinesService
      .update(payload.id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', pipeline);
    return pipeline;
  },
  async deleteById(context: any, id: string): Promise<Pipeline> {
    const pipeline = await pipelinesService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', pipeline);
    return pipeline;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
