
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import KpiVisualsService from '@/api/kpi-visuals/kpi-visuals.service';
import Vue from 'vue';
import Component from 'vue-class-component';
import SalesFunnelChart from '@/components/SalesFunnelChart.vue';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { SalesFunnel } from '@/api/kpi-visuals/sales-funnel.model';
import { User } from '@/api/users/user.model';
import { namespace } from 'vuex-class';
import { Pipeline } from '@/api/pipelines/pipeline.model';
import { Filter } from '@/shared/types/filter.class';

const kpiVisualsService = new KpiVisualsService();

const pipelinesModule = namespace('pipelines');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    SalesFunnelChart,
  },
})
export default class SalesFunnelCardIndividual extends Vue {
  isLoading = true;
  error: string = null;
  salesFunnelResult: SalesFunnel = new SalesFunnel();

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @usersModule.Getter('loggedInUser')
  user!: User;

  @pipelinesModule.Getter('all')
  allPipelines!: Pipeline[];

  @pipelinesModule.Getter('current')
  storedPipeline!: Pipeline;

  @pipelinesModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Pipeline[]>;

  @pipelinesModule.Mutation('setCurrent')
  setSelectedPipeline!: (team?: any) => void;

  selectPipeline(pipeline: any) {
    console.log(pipeline);
    const pipelineObj = this.allPipelines.find(
      (item) => item.name === pipeline,
    );
    this.setSelectedPipeline(pipelineObj);
  }

  get allPipelinesNames() {
    if (this.uiComponent.selectedPipelines.length > 0) {
      return this.uiComponent.selectedPipelines.map((e) => e.name);
    }
    return this.allPipelines.map((e) => e.name);
  }

  get storedPipelineName() {
    return this.storedPipeline && this.storedPipeline.name
      ? this.storedPipeline.name
      : this.allPipelines[this.allPipelines.length - 1].name;
  }

  getPipelineIdByName(name: string) {
    const pipelineObj = this.allPipelines.find((item) => item.name === name);
    return pipelineObj?.id ? pipelineObj?.id : null;
  }

  get title() {
    return this.uiComponent?.title;
  }

  get companyId() {
    if (this.user) {
      if (this.user.company) {
        return this.user.company;
      }
    }
    return '';
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get values() {
    const datasets = [];
    for (const pipelineId of Object.keys(this.processResult)) {
      const dataset = {
        label: this.allPipelines.find(
          (pipeline: Pipeline) => pipeline.id === pipelineId,
        ).name,
        values: Object.values(this.processResult[pipelineId]),
      };
      datasets.push(dataset);
    }
    return datasets;
  }

  get labels() {
    const labelsObject = {};
    if (this.salesFunnelResult?.pipelines) {
      for (const pipeline of this.salesFunnelResult?.pipelines) {
        for (const item of pipeline.funnel) {
          labelsObject[item.name] = 0;
        }
      }
    }
    return Object.keys(labelsObject);
  }

  get processResult() {
    const combinedResult = {};
    if (this.salesFunnelResult?.pipelines) {
      for (const pipeline of this.salesFunnelResult?.pipelines) {
        combinedResult[pipeline.pipeline_id] = {};
        for (const item of pipeline.funnel) {
          if (!combinedResult[pipeline.pipeline_id][item.name]) {
            combinedResult[pipeline.pipeline_id][item.name] = 0;
          }
          combinedResult[pipeline.pipeline_id][item.name] += item.value;
        }
      }
    }
    return combinedResult;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  @Watch('storedPipeline')
  onPipelineChanged() {
    this.loadData();
  }

  get salesFunnelPipeline() {
    return this.getPipelineIdByName(this.storedPipelineName) || '';
  }

  async loadData() {
    this.isLoading = true;
    try {
      await this.fetchAll(new Filter({ company: this.companyId }));
      this.salesFunnelResult =
        await kpiVisualsService.fetchSalesFunnelIndividual(
          this.dateRange,
          this.employeeHoodyIds[0],
          this.salesFunnelPipeline,
        );
      console.log(this.salesFunnelResult);
    } catch (e) {
      this.error = `${e.message}`;
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    try {
      await this.loadData();
    } catch (e) {
      this.error = `${e.message}`;
    } finally {
      this.isLoading = false;
    }
  }
}
