import { BaseModel } from '@/shared/types/base.model';
import { RecordType } from '../integration-records/record-type.enum';
import { KpiRunDetail } from './kpirun-detail.model';

export class IntegrationRun extends BaseModel {
  constructor(
    public name?: string,
    public cron: string = '59 59 * * * *',
    public cronTimezone: string = 'Europe/Brussels',
    public active: boolean = true,
    public onlySync: boolean = true,
    public userField?: string,
    public noraCompany?: string,
    public integration?: string,
    public endpointsToRun?: RecordType[],
    public kpis: KpiRunDetail[] = [],
  ) {
    super();
  }
}
