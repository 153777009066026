import Vue from 'vue';

const state = {
  totalRevenue: {},
  currentSituation: [],
  performance: [],
  submissions: [],
  employeePerformance: {},
  salesnudgeIndexes: [],
};

const getters = {
  totalRevenue: (state: any) => {
    return state.totalRevenue;
  },
  currentSituation: (state: any) => {
    return state.currentSituation;
  },
  performance: (state: any) => {
    return state.performance;
  },
  employeePerformance: (state: any) => {
    return state.employeePerformance;
  },
  submissions: (state: any) => {
    return state.submissions;
  },
  salesnudgeIndexes: (state: any) => {
    return state.salesnudgeIndexes;
  },
};

const mutations = {
  setTotalRevenue(state: any, totalRevenue: any) {
    Vue.set(state, 'totalRevenue', totalRevenue);
  },
  setCurrentSituation(state: any, currentSituation: any[]) {
    Vue.set(state, 'currentSituation', currentSituation);
  },
  setPerformance(state: any, performance: any[]) {
    Vue.set(state, 'performance', performance);
  },
  setSubmissions(state: any, submissions: any[]) {
    Vue.set(state, 'submissions', submissions);
  },
  setSalesnudgeIndexes(state: any, salesnudgeIndex: any[]) {
    Vue.set(state, 'salesnudgeIndexes', salesnudgeIndex);
  },
  setEmployeePerformance(state: any, employeePerformance: any) {
    Vue.set(state, 'employeePerformance', employeePerformance);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
