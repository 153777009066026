import { User } from '@/api/users/user.model';
import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  InferSubjects,
} from '@casl/ability';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = string | 'all';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

function hasRole(user: any, role: string) {
  const found = user.roleMemberships.find((x) => {
    return x.role.id === role;
  });
  return Boolean(found);
}

export function defineRulesFor(user?: User) {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (!user) {
    return rules;
  }
  if (user.isSuperAdmin) {
    can('manage', 'all');
  }

  if (user.isAdmin) {
    can(['read', 'update'], 'Company');
    can('manage', 'User');
    can(['read', 'update'], 'CustomizableUi');
    can('manage', 'Team');
    can('manage', 'Target');
    can(['read'], 'Integration');
    can('read', 'EmployeeDashboard');
    can('read', 'TeamDashboard');
    can('read', 'GeneralDashboard');
  } else if (user.isManager) {
    can('read', 'EmployeeDashboard');
    can('read', 'TeamDashboard');
    can('read', 'GeneralDashboard');
  } else if (user.isEmployee) {
    can('read', 'EmployeeDashboard');
  }

  return rules;
}

export function buildAbilityFor(user?: User) {
  return new AppAbility(defineRulesFor(user), {});
}
