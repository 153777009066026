
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import StaleCompaniesKpiDetailsTable from './StaleCompaniesKpiDetails.vue';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { StaleCompaniesKpiDetailsTable },
})
export default class StaleCompaniesKpiDetails extends Vue {
  isLoading = true;
  tabIndex = 0;

  @Prop()
  data!: any[];

  @Prop()
  kpi!: any;

  get StalesCompaniesLonger() {
    const toShowData = [];
    if (!this.data) return [];
    this.data.map((item: any) => {
      if (item.last_contacted > 180) toShowData.push(item);
    });
    return toShowData;
  }

  get StalesCompaniesOutOfRange() {
    const toShowData = [];
    if (!this.data) return [];
    this.data.map((item: any) => {
      if (item.last_contacted < 180) toShowData.push(item);
    });
    return toShowData;
  }

  created() {
    this.isLoading = true;
    this.isLoading = false;
  }
}
