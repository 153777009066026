
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  Filler,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

ChartJS.register(
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Legend,
);

@Component({
  components: {
    LineChartGenerator,
  },
})
export default class SalesFunnelChart extends Vue {
  colors = [
    '#2C7BE5',
    '#45B1FF',
    '#F09A19',
    '#AE6900',
    '#33B6A5',
    '#00D97E',
    '#EAF0CE',
    '#C0A9B0',
  ];

  @Prop()
  values!: { label: string; values: number[] }[];

  @Prop()
  labels!: string[];

  @Prop()
  dataetNames!: string[];

  get chartData(): ChartData {
    return {
      labels: this.labels,
      datasets: this.values.map((x, index) => {
        return {
          label: x.label,
          backgroundColor: this.colors[index % this.colors.length] + '1A',
          borderColor: this.colors[index % this.colors.length],
          data: x.values,
        };
      }),
    };
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        grid: {
          display: true,
          drawBorder: false,
          borderDashOffset: 50,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        display: true,
        ticks: {},
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        fill: true,
        borderWidth: 2,
        borderColor: '#2C7BE5',
        backgroundColor: '#2C7BE51A',
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };
}
