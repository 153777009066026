import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { Translation } from './translation.model';

export default class TranslationsService extends BaseService<Translation> {
  get endpoint() {
    return 'translations';
  }

  async translationsFile(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/json`);
    return response.data;
  }
}
