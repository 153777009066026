
import { Bar as BarChartGenerator } from 'vue-chartjs/legacy';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  Filler,
  ChartOptions,
  LinearScale,
  BarElement,
  PointElement,
  ChartEvent,
  ActiveElement,
  Tooltip,
  LineElement,
  LineController,
} from 'chart.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';

ChartJS.register(
  BarElement,
  LineElement,
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Legend,
  Tooltip,
  annotationPlugin,
);

@Component({
  components: {
    BarChartGenerator,
  },
})
export default class WeightedPipelineChart extends Vue {
  isLoading = false;
  colors = [
    '#33B6A5',
    '#E63757',
    '#F09A19',
    '#AE6900',
    '#33B6A5',
    '#00D97E',
    '#EAF0CE',
    '#C0A9B0',
  ];

  get maxLineAnnotation() {
    if (this.uiComponent.showMaxLine) {
      return {
        type: 'line',
        xMin: this.uiComponent.maxNumber,
        xMax: this.uiComponent.maxNumber,
        borderColor: 'rgb(255, 0, 0)',
        borderWidth: 2,
      };
    }
    return this.defaultLineAnnotation;
  }

  get line1Annotation() {
    if (this.uiComponent.annotation1Active) {
      return {
        type: 'line',
        xMin: this.uiComponent.annotation1IsAverage
          ? Number(this.averageValue[0].toFixed(2))
          : this.uiComponent.annotation1Value,
        xMax: this.uiComponent.annotation1IsAverage
          ? Number(this.averageValue[0].toFixed(2))
          : this.uiComponent.annotation1Value,
        scaleID: 'x',
        value: this.uiComponent.annotation1IsAverage
          ? Number(this.averageValue[0].toFixed(2))
          : this.uiComponent.annotation1Value,
        label: {
          // Label configuration
          position: 'start', // Position the label at the top of the line
          backgroundColor: 'rgb(0, 0, 255)',
          content:
            Number(this.averageValue[0].toFixed(2)) > 0
              ? this.uiComponent.annotation1Label
              : '', // The label text
          display: true,
          xAdjust: 40,
          font: {
            size: 11,
          },
          padding: {
            top: 1.8,
            bottom: 1.8,
            left: 2,
            right: 2,
          },
        },
        borderColor: 'rgb(0, 0, 255)',
        borderWidth: 2,
        borderDash: [5],
      };
    }
    return this.defaultLineAnnotation;
  }

  get line2Annotation() {
    if (this.uiComponent.annotation2Active) {
      return {
        type: 'line',
        xMin: this.uiComponent.annotation2IsAverage
          ? Number(this.averageValue[1].toFixed(2))
          : this.uiComponent.annotation2Value,
        xMax: this.uiComponent.annotation2IsAverage
          ? Number(this.averageValue[1].toFixed(2))
          : this.uiComponent.annotation2Value,
        scaleID: 'x',
        value: this.uiComponent.annotation2IsAverage
          ? Number(this.averageValue[1].toFixed(2))
          : this.uiComponent.annotation2Value,
        label: {
          // Label configuration
          position: 'start', // Position the label at the top of the line
          backgroundColor: 'rgb(0, 0, 255)',
          content:
            Number(this.averageValue[1].toFixed(2)) > 0
              ? this.uiComponent.annotation2Label
              : '', // The label text
          display: true,
          xAdjust: 70,
          font: {
            size: 11,
          },
          padding: {
            top: 1.8,
            bottom: 1.8,
            left: 2,
            right: 2,
          },
        },
        borderColor: 'rgb(0, 0, 255)',
        borderWidth: 2,
        borderDash: [5],
      };
    }
    return this.defaultLineAnnotation;
  }

  get defaultLineAnnotation() {
    return {
      type: 'line',
      xMin: 0,
      xMax: 0,
      borderColor: 'rgba(0, 0, 255, 0)',
      borderWidth: 2,
    };
  }

  get chartOptions() {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      /*    onClick: this.handler,*/
      scales: {
        y: {
          display: true,
          grid: {
            display: true,
            drawBorder: false,
            lineWidth: 0,
          },
        },
        x: {
          display: true,
          ticks: {
            stepSize: 1,
          },
          grid: {
            display: true,
          },
        },
      },
      elements: {},
      plugins: {
        legend: {
          position: 'bottom',
        },
        annotation: {
          annotations: {
            line1: this.maxLineAnnotation,
            line2: this.line1Annotation,
            line3: this.line2Annotation,
          },
        },
      },
    };
  }

  get averageValue() {
    const x = [];
    this.values.forEach((obj) => {
      const sum = obj.values.reduce((partialSum, a) => partialSum + a, 0);
      x.push(sum / obj.values.length);
    });
    return x;
  }

  @Prop()
  values!: { label: string; values: number[] }[];

  @Prop()
  labels!: string[];

  @Prop()
  uiComponent!: CustomizableUIComponent;

  @Watch('values')
  onValuesChanged() {
    this.loadData();
  }

  @Watch('labels')
  onLabelsChanged() {
    this.loadData();
  }

  @Watch('uiComponent')
  onUiComponentChanged() {
    this.loadData();
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.chartOptions;
    } finally {
      this.isLoading = false;
    }
  }

  get chartData(): ChartData {
    this.averageValue;
    const x = {
      labels: this.labels,
      datasets: this.values.map((x, index) => {
        return {
          label: x.label,
          backgroundColor: this.colors[index % this.colors.length],
          data: x.values,
          barPercentage: 1,
          categoryPercentage: 0.6,
        };
      }),
    };
    return x;
  }

  get maxLine() {
    return this.uiComponent.showMaxLine ? this.uiComponent.maxNumber : null;
  }

  @Emit('click')
  handler(ctx: ChartEvent, elements: ActiveElement[]) {
    const firstElement = elements[0];
    if (!firstElement) {
      return;
    }
    return {
      kpiIndex: firstElement.datasetIndex,
      userIndex: firstElement.index,
    };
  }
}
