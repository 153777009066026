import axios from '@/plugins/axios';
import { Log } from './log.model';
import BaseService from '../base-service';
import { Filter } from '@/shared/types/filter.class';

export default class LogsService extends BaseService<Log> {
  get endpoint() {
    return 'logs';
  }
}
