import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import store from '@/store';
import { AppConfig } from '@/config';

const http = axios.create({
  baseURL: AppConfig.VUE_APP_API,
});

http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = store.getters['auth/token'];

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const rememberMeToken = store.getters['auth/rememberMeToken'];
    const rememberMeSeries = store.getters['auth/rememberMeSeries'];

    if (rememberMeSeries) {
      config.headers[
        'X-Remember-Me'
      ] = `${rememberMeSeries} ${rememberMeToken}`;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default http;
