import { Log } from '@/api/logs/log.model';
import LogsService from '@/api/logs/logs.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const logsService = new LogsService();

const state = {
  current: new Log(),
  all: [],
  pagination: {},
};

const getters = {
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, log: Log) {
    Vue.set(state, 'current', log);
  },
  setAll(state: any, logs: PaginateResult<Log>) {
    Vue.set(state, 'all', logs.docs);
  },
};

const actions = {
  async fetchById(context: any, id: string) {
    try {
      const log = await logsService.fetchOne(id);
      context.commit('setCurrent', log);
      return log;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchAll(context: any, payload?: Filter): Promise<Log[]> {
    const logs = await logsService.fetch(payload);
    context.commit('setPagination', logs);
    context.commit('setAll', logs);
    return logs.docs;
  },
  async delete(context: any, id: string): Promise<Log> {
    try {
      return logsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, log: Log): Promise<Log> {
    try {
      return await logsService.update(log._id || '', log);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, log: Log): Promise<Log> {
    try {
      const result = await logsService.create(log);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
