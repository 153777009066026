import { IssueReport } from '@/api/issue-reports/issue-report.model';
import IssueReportsService from '@/api/issue-reports/issue-reports.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const issueReportsService = new IssueReportsService();

const state = {
  current: new IssueReport(),
  all: [],
  pagination: {},
};

const getters = {
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, issueReport: IssueReport) {
    Vue.set(state, 'current', issueReport);
  },
  setAll(state: any, issueReports: IssueReport[]) {
    Vue.set(state, 'all', issueReports);
  },
};

const actions = {
  async fetchById(context: any, id: string) {
    try {
      const issueReport = await issueReportsService.fetchOne(id);
      context.commit('setCurrent', issueReport);
      return issueReport;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchAll(context: any, payload?: Filter): Promise<IssueReport[]> {
    const issueReports = await issueReportsService.fetchAll(payload);
    context.commit('setAll', issueReports);
    return issueReports;
  },
  async delete(context: any, id: string): Promise<IssueReport> {
    try {
      return issueReportsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, issueReport: IssueReport): Promise<IssueReport> {
    try {
      return await issueReportsService.update(
        issueReport._id || '',
        issueReport,
      );
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, issueReport: IssueReport): Promise<IssueReport> {
    try {
      const result = await issueReportsService.create(issueReport);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
