
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import KpiVisualsService from '@/api/kpi-visuals/kpi-visuals.service';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';
import WeightedPipelineChart from '@/components/WeightedPipelineChart.vue';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { WeightedPipeline } from '@/api/kpi-visuals/weighted-pipeline.model';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { DateUtil } from '@/shared/util/date.util';
import { namespace } from 'vuex-class';
import { Pipeline } from '@/api/pipelines/pipeline.model';
import { Filter } from '@/shared/types/filter.class';

const kpiVisualsService = new KpiVisualsService();
const kpiValuesService = new KpiValuesService();

const pipelinesModule = namespace('pipelines');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    WeightedPipelineChart,
  },
})
export default class ArrMrrWeightedPipelineCard extends Vue {
  isLoading = true;
  weightedPipeline: WeightedPipeline = new WeightedPipeline();
  kpiValuesResult: KpiValueResult = new KpiValueResult();
  error: string = null;
  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @usersModule.Getter('loggedInUser')
  user!: User;

  @pipelinesModule.Getter('all')
  allPipelines!: Pipeline[];

  @pipelinesModule.Getter('current')
  storedPipeline!: Pipeline;

  @pipelinesModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Pipeline[]>;

  @pipelinesModule.Mutation('setCurrent')
  setSelectedPipeline!: (team?: any) => void;

  selectPipeline(pipeline: any) {
    const pipelineObj = this.allPipelines.find(
      (item) => item.name === pipeline,
    );
    this.setSelectedPipeline(pipelineObj);
  }

  get allPipelinesNames() {
    if (this.uiComponent.selectedPipelines.length > 0) {
      return this.uiComponent.selectedPipelines.map((e) => e.name);
    }
    return this.allPipelines.map((e) => e.name);
  }

  get storedPipelineName() {
    return this.storedPipeline && this.storedPipeline.name
      ? this.storedPipeline.name
      : this.allPipelines[this.allPipelines.length - 1].name;
  }

  get title() {
    return this.uiComponent?.title;
  }

  get companyId() {
    if (this.user) {
      if (this.user.company) {
        return this.user.company;
      }
    }
    return '';
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get values() {
    const datasets = [];
    let datasetKey;
    let index = 0;

    for (datasetKey of this.datasetKeys) {
      const dataset = { label: datasetKey, values: [] };
      for (const month of this.weightedPipeline.months) {
        dataset.values.push(month.values[index].value);
      }
      datasets.push(dataset);
      index++;
    }
    return datasets;
  }

  get translatedKpiId() {
    return this.$t(this.uiComponent?.kpi);
  }

  get newKpiValues() {
    const values = [];
    if (!this.uiComponent.showAvgDealLineWeightedPipeline) {
      return [];
    }
    if (
      !this.weightedPipeline?.months ||
      this.weightedPipeline?.months.length === 0
    ) {
      return values;
    }

    for (const kpiValue of this.weightedPipeline?.months) {
      values.push(kpiValue.average_open);
    }
    return values;
  }

  get kpiValues() {
    const values = [];
    if (
      !this.kpiValuesResult?.kpi_values ||
      this.kpiValuesResult?.kpi_values.length === 0
    ) {
      return values;
    }
    for (const kpiValue of this.kpiValuesResult?.kpi_values) {
      values.push(kpiValue.value);
    }
    return values;
  }

  get totalValueTodayAbsolute() {
    if (
      !this.weightedPipeline?.months ||
      this.weightedPipeline?.months.length === 0
    ) {
      return 0;
    }
    const months = this.weightedPipeline?.months;
    const lastMonth = months[months.length - 1];
    let sum = 0;
    for (const value of lastMonth.values) {
      sum += value.absolute_value;
    }
    if (Number.isNaN(sum)) return 0;
    return sum;
  }

  get totalValueToday() {
    if (
      !this.weightedPipeline?.months ||
      this.weightedPipeline?.months.length === 0
    ) {
      return 0;
    }
    /*    let sum = 0;
        for (const month of this.weightedPipeline?.months) {
          for (const value of month.values) {
            sum += value.value;
          }
        }*/
    const months = this.weightedPipeline?.months;
    const lastMonth = months[months.length - 1];
    let sum = 0;
    for (const value of lastMonth.values) {
      sum += value.value;
    }
    return sum;
  }

  get totalValueTodayFormatted() {
    return `${Formatter.intToReadableString(this.totalValueToday)}`;
  }

  get totalAbsoluteValueTodayFormatted() {
    return `${Formatter.intToReadableString(this.totalValueTodayAbsolute)}`;
  }

  get datasetKeys() {
    if (!this.weightedPipeline.months[0]) {
      return [];
    }
    return this.weightedPipeline.months[0].values.map((x) => x.name);
  }

  get labels() {
    return this.weightedPipeline.months.map((m) => m.name);
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  @Watch('storedPipeline')
  onPipelineChanged() {
    this.loadData();
  }

  getPipelineIdByName(name: string) {
    const pipelineObj = this.allPipelines.find((item) => item.name === name);
    return pipelineObj.id;
  }

  async loadData() {
    this.isLoading = true;
    try {
      await this.fetchAll(new Filter({ company: this.companyId }));
      if (this.allPipelines.length > 1) {
        this.weightedPipeline = await kpiVisualsService.fetchWeightedPipeline(
          this.dateRange,
          this.getPipelineIdByName(this.storedPipelineName),
        );
      } else {
        this.weightedPipeline = await kpiVisualsService.fetchWeightedPipeline(
          this.dateRange,
        );
      }
    } catch (e) {
      this.error = `${e.message}`;
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    try {
      await this.loadData();
    } catch (e) {
      this.error = `${e.message}`;
    }
  }
}
