
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange, DateRangeEnum } from '../DateRangeSelect.vue';
import { DateUtil } from '@/shared/util/date.util';
import { namespace } from 'vuex-class';
import { Company } from '@/api/companies/company.model';
import { Team } from '@/api/teams/team.model';

const kpiValuesService = new KpiValuesService();

const companiesModule = namespace('companies');
const teamsModule = namespace('teams');

interface Interval {
  start: string;
  stop: string;
}

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class KpiComparisonIntervalCard extends Vue {
  isLoading = false;
  kpiValuesResult: KpiValueResult = {} as never;
  kpiComparisonIntervalResult: KpiValueResult = {} as never;
  interval: Interval = {} as never;
  error: string = null;

  @Prop({ required: true })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @teamsModule.Getter('current')
  selectedTeam!: Team;

  get pluralInterval() {
    return this.numberOfIntervals > 1
      ? this.$t(`${this.dateRangeInterval}s`)
      : this.$t(this.dateRangeInterval);
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  get numberOfIntervals() {
    return this.uiComponent.numberOfIntervals || 4;
  }

  get singleInterval() {
    return this.$t(this.dateRange?.interval) || this.$t('weeks');
  }

  get dateRangeInterval() {
    this.dateRangeIntervalValue();
    return this.dateRange?.interval || 'weeks';
  }

  firstKpiValue(value: KpiValueResult) {
    if (value?.kpi_values && value?.kpi_values.length > 0) {
      return value?.kpi_values[0];
    }
    return { value: 0, target: 0 };
  }

  multipleKpiValue(value: KpiValueResult) {
    let total = 0;
    if (value?.kpi_values && value?.kpi_values.length > 0) {
      value?.kpi_values.forEach((val) => {
        total += val.value;
      });
      return { value: total, target: 0 };
    }
    return { value: 0, target: 0 };
  }

  valueThisWeekFormatted(values: KpiValueResult) {
    const divider = this.employees.length;
    const value = this.valueMultiple(values) / divider;
    if (this.formatter) {
      return Formatter[this.formatter](value);
    }
    return value;
  }

  valuePreviousFormatted(values: KpiValueResult) {
    let divider = this.employees.length * this.uiComponent.numberOfIntervals;
    if (
      this.selectedTeam &&
      this.selectedTeam.company === '649d3e39f266a3404f48df94'
    ) {
      if (this.selectedTeam && this.selectedTeam.members.length > 0) {
        divider = this.selectedTeam.members.length * 4;
      }
    }
    const value = this.valueMultiple(values) / divider;
    if (this.formatter) {
      return Formatter[this.formatter](value);
    }
    return value;
  }

  dateRangeIntervalValue() {
    if (Object.values(DateRangeEnum).includes(this.dateRange?.interval)) {
      const dates = DateUtil.getStartEndOfIntervalFromIso(
        this.dateRange.start,
        this.numberOfIntervals,
        this.dateRange?.interval,
      );
      this.interval.start = dates.start;
      this.interval.stop = dates.end;
    }
  }

  value(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['value'];
  }

  valueMultiple(values: KpiValueResult) {
    const kpi = this.multipleKpiValue(values);
    return kpi['value'];
  }

  target(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['target'];
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  get teamHoodyIDs() {
    let ids = [];
    if (this.selectedTeam && this.selectedTeam.members.length > 0) {
      ids = this.selectedTeam.members.map((e) => e.hoodyUser);
    }
    return ids;
  }

  getWindow() {
    if (this.dateRange?.interval === 'week') return 'W';
    if (this.dateRange?.interval === 'month') return 'M';
    if (this.dateRange?.interval === 'quarter') return 'Q';
    return 'W';
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.kpiValuesResult = await kpiValuesService.fetchKpiValuesForComponent(
        this.uiComponent,
        this.dateRange,
        this.teams,
        this.employeeHoodyIds,
        this.managers,
      );
      this.dateRangeIntervalValue();
      const newDateRange = new DateRange();
      newDateRange.start = this.interval['start'];
      newDateRange.stop = this.interval['stop'];
      if (
        this.selectedTeam &&
        this.selectedTeam.company === '649d3e39f266a3404f48df94'
      ) {
        this.kpiComparisonIntervalResult =
          await kpiValuesService.fetchKpiValuesForKpi(
            this.uiComponent.kpi,
            this.interval.start,
            this.interval.stop,
            [],
            this.teamHoodyIDs,
            [],
            this.getWindow(),
          );
      } else {
        this.kpiComparisonIntervalResult =
          await kpiValuesService.fetchKpiValuesForKpi(
            this.uiComponent.kpi,
            newDateRange.start,
            newDateRange.stop,
            [],
            this.employeeHoodyIds,
            [],
            this.getWindow(),
          );
      }
    } catch (e) {
      this.error = `${e.message} - ${e.config.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
