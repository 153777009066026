import axios from '@/plugins/axios';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { Filter } from '@/shared/types/filter.class';

export default class BaseService<T> {
  get endpoint() {
    return '/';
  }

  async create(entity: T): Promise<T> {
    const response = await axios.post<T>(this.endpoint, entity);
    return response.data;
  }

  async fetch(params?: Filter): Promise<PaginateResult<T>> {
    const response = await axios.get<PaginateResult<T>>(this.endpoint, {
      params: params?.toParams(),
    });

    return response.data;
  }

  async fetchAll(params?: Filter): Promise<T[]> {
    const response = await axios.get<T[]>(`${this.endpoint}/all`, {
      params: params?.toParams(),
    });

    return response.data;
  }

  async count(params?: Filter): Promise<number> {
    const response = await axios.get<number>(`${this.endpoint}/count`, {
      params: params?.toParams(),
    });
    return response.data;
  }

  async fetchOne(id: string): Promise<T> {
    const response = await axios.get<T>(`${this.endpoint}/${id}`);
    return response.data;
  }

  async update(id: string, entity: T): Promise<T> {
    const response = await axios.put<T>(`${this.endpoint}/${id}`, entity);
    return response.data;
  }

  async partialUpdate(id: string, entity: T): Promise<T> {
    const response = await axios.patch<T>(`${this.endpoint}/${id}`, entity);
    return response.data;
  }

  async delete(id: string): Promise<T> {
    const response = await axios.delete(`${this.endpoint}/${id}`);
    return response.data;
  }
}
