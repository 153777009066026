import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { IntegrationRecord } from './integration-record.model';

export default class IntegrationRecordsService extends BaseService<IntegrationRecord> {
  get endpoint() {
    return 'ingest/integration-records';
  }

  async fetchAllHistoricalRecordsForExternalId(
    id: string,
    integration: string,
  ): Promise<IntegrationRecord[]> {
    const response = await axios.get<IntegrationRecord[]>(
      `${this.endpoint}/${id}/historical`,
      { params: { integration } },
    );

    return response.data;
  }
}
