
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { DateRangeEnum } from '@/components/DateRangeSelect.vue';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class EmployeePerformanceCardDateHeader extends Vue {
  @Prop({})
  date!: string;

  @Prop({})
  interval!: string;

  @Prop({ default: false })
  isLast!: boolean;

  get dateAsIntervalCount() {
    if (this.isLast) {
      if (this.interval === DateRangeEnum.Quarter) {
        return `${this.$t('views.individual.currentQuarter')} ${this.$t(
          this.interval,
        )}`;
      } else {
        return `${this.$t('views.individual.current')} ${this.$t(
          this.interval,
        )}`;
      }
    }
    let weekNumber;
    if (this.interval === DateRangeEnum.Week) {
      weekNumber = DateTime.fromISO(this.date).weekNumber;
    } else if (this.interval === DateRangeEnum.Month) {
      weekNumber = DateTime.fromISO(this.date).monthShort;
      weekNumber = this.$t(weekNumber.toLowerCase() as string);
    } else if (this.interval === DateRangeEnum.Quarter) {
      weekNumber = DateTime.fromISO(this.date).quarter;
      const year = DateTime.fromISO(this.date).year;
      weekNumber = `${weekNumber} ${year}`;
    }

    return `${this.$t(this.interval)} ${weekNumber}`;
  }

  get dateAsRange() {
    const dateTime = DateTime.fromISO(this.date);
    const startOfInterval = dateTime.startOf(this.interval as any);
    const endOfInterval = dateTime.endOf(this.interval as any);
    const format = 'dd.MM';
    const startFormatted = startOfInterval.toFormat(format);
    const endFormatted = endOfInterval.toFormat(format);
    return `${startFormatted} - ${endFormatted}`;
  }
}
