import Vue from 'vue';
import Vuex from 'vuex';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const state = {
  loaders: {},
  vm: null,
};

const getters = {
  loaders: (state: any) => {
    return state.loaders;
  },
  isLoading: (state: any) => (id: string) => {
    return state.loaders[id];
  },
};

const mutations = {
  addLoader(state: any, id: string) {
    Vue.set(state.loaders, id, true);
  },
  removeLoader(state: any, id: string) {
    Vue.delete(state.loaders, id);
  },
  addToast(state: any, toast: Toast) {
    state.vm.$bvToast.toast(toast.message, {
      title: state.vm.$t(toast.type),
      toaster: 'b-toaster-bottom-right',
      appendToast: true,
      solid: true,
      variant: toast.type,
    });
  },
  setAppContext(state: any, vm: Vue) {
    state.vm = vm;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
