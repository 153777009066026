import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { HoodyUser } from './hoody-user.model';

export default class HoodyUsersService extends BaseService<HoodyUser> {
  get endpoint() {
    return 'hoody-users';
  }

  async fetchByCompany(id: string): Promise<HoodyUser[]> {
    const response = await axios.get<any[]>(
      `${this.endpoint}/for-company/${id}`,
    );
    return response.data;
  }
}
