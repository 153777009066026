import { BaseModel } from '@/shared/types/base.model';

export class Target extends BaseModel {
  constructor(
    public user_id: string,
    public company_id: string,
    public kpi_id?: string,
    public from_period: string = new Date().toISOString(),
    public target: number = 0,
  ) {
    super();
  }
}
