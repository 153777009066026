import { DateRange } from '@/components/DateRangeSelect.vue';
import axios from '@/plugins/axios';
import { DateTime } from 'luxon';
import BaseService from '../base-service';
import { CustomizableUIComponent } from '../customizable-uis/customizable-ui.model';
import { KpiValueResult } from './kpi-value-result.model';

export default class KpiValuesService extends BaseService<KpiValueResult> {
  get endpoint() {
    return 'kpi-values';
  }

  async fetchKpiValuesForComponent(
    component: CustomizableUIComponent,
    dateRange: DateRange,
    teams?: string[],
    users?: string[],
    managers?: string[],
  ) {
    let start;
    let stop;
    if (component.type !== 'CompanyAvgComparisonCard') {
      start = this.calculateStartDate(dateRange, component.isYTD);
      stop = this.calculateStopDate(dateRange, component.isYTD);
    } else {
      start = dateRange.start;
      stop = dateRange.stop;
    }

    if (!component.kpis || component.kpis.length === 0) {
      const kpi = component.kpi;
      if (component.isYTD) {
        if (component.isYTDOldSpec) {
          return this.fetchKpiValuesForKpi(
            kpi,
            start,
            stop,
            teams,
            users,
            managers,
          );
        } else {
          return this.fetchKpiValuesForKpiBasedOnIntegration(
            kpi,
            start,
            stop,
            teams,
            users,
            managers,
          );
        }
      } else {
        return this.fetchKpiValuesForKpi(
          kpi,
          start,
          stop,
          teams,
          users,
          managers,
        );
      }
    }

    let resultsArray = [];
    const resultPromises = [];
    for (const kpi of component.kpis) {
      resultPromises.push(
        this.fetchKpiValuesForKpi(kpi, start, stop, teams, users, managers),
      );
    }
    resultsArray = await Promise.all(resultPromises);
    return resultsArray;
  }

  async fetchKpiValuesSourceForComponent(
    component: CustomizableUIComponent,
    dateRange: DateRange,
    sources?: string[],
    window?: string,
  ) {
    let start;
    let stop;
    if (component.type !== 'CompanyAvgComparisonCard') {
      start = this.calculateStartDate(dateRange, component.isYTD);
      stop = this.calculateStopDate(dateRange, component.isYTD);
    } else {
      start = dateRange.start;
      stop = dateRange.stop;
    }

    if (!component.kpis || component.kpis.length === 0) {
      return this.fetchKpiValuesForSourceKpi(
        component.kpi,
        start,
        stop,
        sources,
        window,
      );
    }

    let resultsArray = [];
    const resultPromises = [];
    for (const kpi of component.kpis) {
      resultPromises.push(
        this.fetchKpiValuesForSourceKpi(kpi, start, stop, sources, window),
      );
    }
    resultsArray = await Promise.all(resultPromises);
    return resultsArray;
  }

  async fetchKpiValuesForSourceKpi(
    kpi: string,
    start: string,
    stop: string,
    source: string[],
    window?: string,
  ) {
    const response = await axios.get<any>(`${this.endpoint}/source/${kpi}`, {
      params: {
        start: start,
        stop: stop,
        source: source,
        window,
      },
    });

    return response.data;
  }

  async fetchKpiValuesForComponentWithStartStop(
    component: CustomizableUIComponent,
    start: string,
    stop: string,
    teams?: string[],
    users?: string[],
    managers?: string[],
  ) {
    if (!component.kpis || component.kpis.length === 0) {
      const kpi = component.kpi;
      return this.fetchKpiValuesForKpi(
        kpi,
        start,
        stop,
        teams,
        users,
        managers,
      );
    }

    const resultsArray = [];
    for (const kpi of component.kpis) {
      const result = await this.fetchKpiValuesForKpi(
        kpi,
        start,
        stop,
        teams,
        users,
        managers,
      );
      resultsArray.push(result);
    }
    return resultsArray;
  }

  async fetchKpiValuesForKpi(
    kpi: string,
    start: string,
    stop: string,
    teams?: string[],
    users?: string[],
    managers?: string[],
    window?: string,
  ) {
    const response = await axios.get<any>(`${this.endpoint}/${kpi}`, {
      params: {
        start: start,
        stop: stop,
        user: users,
        team: teams,
        manager: managers,
        window,
      },
    });

    return response.data;
  }

  async fetchKpiValuesForKpiBasedOnIntegration(
    kpi: string,
    start: string,
    stop: string,
    teams?: string[],
    users?: string[],
    managers?: string[],
    window?: string,
  ) {
    const response = await axios.get<any>(
      `${this.endpoint}/${kpi}/integration`,
      {
        params: {
          start: start,
          stop: stop,
          user: users,
          team: teams,
          manager: managers,
          window,
        },
      },
    );

    return response.data;
  }

  private calculateStartDate(dateRange: DateRange, isYTD = false): string {
    if (isYTD) {
      return DateTime.fromISO(dateRange.start)
        .startOf('year')
        .toISO({ includeOffset: false });
    }
    return DateTime.fromISO(dateRange.start)
      .startOf(dateRange.interval as any)
      .toISO({ includeOffset: false });
  }

  private calculateStopDate(dateRange: DateRange, isYTD = false): string {
    if (isYTD) {
      return DateTime.fromISO(dateRange.stop)
        .endOf('day')
        .toISO({ includeOffset: false });
    }
    return DateTime.fromISO(dateRange.start)
      .endOf(dateRange.interval as any)
      .toISO({ includeOffset: false });
  }
}
