
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import { User } from './api/users/user.model';
import { buildAbilityFor } from './shared/define-rules';

const appModule = namespace('app');
const usersModule = namespace('users');
const authModule = namespace('auth');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class App extends Vue {
  @authModule.Getter('isImpersonating')
  isImpersonating!: boolean;

  @usersModule.Getter('loggedInUser')
  user!: User;

  @appModule.Mutation('setAppContext')
  setAppContext!: (vm: Vue) => void;

  @Watch('user')
  onUserChanged() {
    this.$ability.update(buildAbilityFor(this.user).rules);
    this.$gtag.set({
      impersonate: this.isImpersonating,
      company: this.user?.company?.id || '',
    });
  }

  get layout() {
    if (
      this.$route.meta &&
      (this.$route.meta.protected || this.$route.meta.showSidebar)
    ) {
      return 'sidebar-layout';
    }
    return 'no-sidebar-layout';
  }

  created() {
    this.setAppContext(this);
    this.$ability.update(buildAbilityFor(this.user).rules);
  }
}
