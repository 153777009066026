
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  ChartType,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ColorUtils } from '@/shared/util/color.util';
import { User } from '@/api/users/user.model';
import moment, { unitOfTime } from 'moment';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { DateRange } from '@/components/DateRangeSelect.vue';
import { DateTime } from 'luxon';
import { Team, TeamMember } from '@/api/teams/team.model';
import { namespace } from 'vuex-class';
import _ from 'lodash';

ChartJS.register(
  LineElement,
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Legend,
  Tooltip,
  annotationPlugin,
);

@Component({
  components: {
    LineChartGenerator,
  },
})
export default class EmployeeProgressionChart extends Vue {
  @Prop()
  values!: KpiValueResult;

  @Prop()
  company!: string;

  @Prop()
  employees!: User[];

  @Prop()
  teams!: Team[];

  @Prop()
  dateRange!: DateRange;

  range = this.dateRange;

  getUserName(id: string): string {
    const employee = this.employees.find((x) => x.hoodyId === id);
    return employee ? employee.name : 'N/A';
  }

  getUserColor(index, userId) {
    return ColorUtils.getUniqueColorForIndex(index, userId);
  }

  getRemovedUsers(): TeamMember[] {
    let newEmployees: User[] = _.cloneDeep(this.employees);
    const deletedTeamMembers: TeamMember[] = [];
    if (!this.teams || this.teams.length === 0) return deletedTeamMembers;

    const team: Team = this.teams[0];
    team.members.forEach((member) => {
      if (!member.until) return;
      deletedTeamMembers.push(member);
    });
    return deletedTeamMembers;
  }

  get chartData(): ChartData {
    const delMembers = this.getRemovedUsers();
    const delUsersIDs = delMembers.map((item: TeamMember) => item.hoodyUser);
    const datasetsByUser = {} as any;
    const labels = [];
    let index = 0;
    for (const value of this.values.kpi_values) {
      if (!datasetsByUser[value.user_id]) {
        const userIndex = this.employees.findIndex(
          (el) => el.id === value.user_id,
        );
        const color = this.getUserColor(index, value.user_id);
        datasetsByUser[value.user_id] = {
          userIndex,
          label: this.getUserName(value.user_id),
          data: [],
          backgroundColor: color,
          borderWidth: 3,
          borderColor: color,
          fill: false,
          tension: 0.3,
        };
        ++index;
      }
      let label;
      let intervalNumber;
      const intervalYear = DateTime.fromISO(value.day).year;
      switch (this.dateRange.interval) {
        case 'week':
          intervalNumber = DateTime.fromISO(value.day).weekNumber;
          label = `W${intervalNumber} ${intervalYear}`;
          break;
        case 'month':
          intervalNumber = DateTime.fromISO(value.day).monthShort;
          label = `${intervalNumber} ${intervalYear}`;
          break;
        case 'quarter':
          intervalNumber = DateTime.fromISO(value.day).quarter;
          label = `Q${intervalNumber} ${intervalYear}`;
          break;
      }

      // const label2 = `W${moment(value.day).isoWeek()}`;
      if (!labels.includes(label)) {
        labels.push(label);
      }

      if (delUsersIDs.includes(value.user_id)) {
        const member = delMembers.find(
          (item: TeamMember) => item.hoodyUser === value.user_id,
        );
        const day = DateTime.fromISO(value.day);
        const until = DateTime.fromISO(member.until);

        if (day > until) {
          datasetsByUser[value.user_id].data.push(null);
        } else {
          datasetsByUser[value.user_id].data.push(Math.round(value.value));
        }
      } else {
        datasetsByUser[value.user_id].data.push(Math.round(value.value));
      }
    }

    const ds = {} as ChartData;
    ds.labels = labels;
    const dd: any[] = Object.values(datasetsByUser).sort(
      (a, b) => a['userIndex'] - b['userIndex'],
    );
    ds.datasets = dd;
    return ds;
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {},
      },
      y: {
        grid: {
          borderDash: [8, 4],
          drawBorder: false,
        },
        ticks: {
          display: true,
          font: {
            family: 'Inter',
            size: 13,
          },
        },
        min: 0,
        max: 140,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: 100,
            yMax: 100,
            borderColor: 'rgb(255, 0, 0)',
            borderWidth: 2,
          },
        },
      },
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'index',
        callbacks: {
          title: function (tooltipItems: any): string {
            const value = tooltipItems[0].label;
            let interval:
              | unitOfTime.Base
              | unitOfTime._quarter
              | unitOfTime._isoWeek;
            let isoWeekOfYear;
            let date;
            let firstDayOfInterval;
            let lastDayOfInterval;
            if (value.includes('W')) {
              interval = 'isoWeek';
              isoWeekOfYear = value.split(' ')[0].split('W')[1];
              date = moment(isoWeekOfYear, 'W WW');
              firstDayOfInterval = moment(date)
                .startOf(interval)
                .format('MMM Do');
              lastDayOfInterval = moment(date).endOf(interval).format('MMM Do');
            } else if (value.includes('Q')) {
              interval = 'Q';
              isoWeekOfYear = value.split(' ')[0].split('Q')[1];
              date = moment(isoWeekOfYear, 'Q YYYY');
              firstDayOfInterval = date;
              lastDayOfInterval = moment(firstDayOfInterval)
                .endOf('quarter')
                .format('MMM Do');
              firstDayOfInterval = firstDayOfInterval.format('MMM Do');
            } else {
              interval = 'M';
              isoWeekOfYear = value;
              date = moment(isoWeekOfYear, 'MMM YYYY');
              const year = moment(date).year();
              const month = moment(date).month();
              firstDayOfInterval = moment([year, month]);
              lastDayOfInterval = moment(firstDayOfInterval)
                .endOf('month')
                .format('MMM Do');
              firstDayOfInterval = firstDayOfInterval.format('MMM Do');
            }

            return `${interval === 'isoWeek' ? 'Week ' : ''}${
              interval === 'Q' ? 'Q' : ''
            }${isoWeekOfYear}: ${firstDayOfInterval} - ${lastDayOfInterval}`;
          },
        },
      },
    },
  };
}
