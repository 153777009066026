
import { CustomizableUiKpiDetail } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValue } from '@/api/kpi-values/kpi-value-result.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import { User } from '@/api/users/user.model';
import KpiDetailModal from './KpiDetailModal.vue';

const customizableUIsModule = namespace('customizableUIs');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { KpiDetailModal } })
export default class DealSourceKpiValueBadgeColumn extends Vue {
  showKpiDetail = false;
  @Prop()
  kpiValue!: KpiValue | string;

  @Prop()
  kpi!: string;

  @Prop()
  source!: any;

  @customizableUIsModule.Getter('kpiDetails')
  kpiDetails!: CustomizableUiKpiDetail[];

  get classes() {
    const softColor = this.softColor;
    const classes = `badge-fixed py-2 ${softColor}`;
    if (this.hasKpiDetail) {
      return `${classes}  cursor-pointer`;
    }
    return classes;
  }

  get kpiDetail() {
    if (!this.kpiDetails || this.kpiDetails.length === 0) {
      return null;
    }
    return this.kpiDetails.find((kd) => kd.kpi === this.kpi);
  }

  get hasKpiDetail() {
    if (!this.kpiDetail || !this.kpiDetail.fields?.length) {
      return false;
    }
    return true;
  }

  get valueFormatted() {
    if (typeof this.value === 'string') return this.value;
    if (
      this.kpi === 'e9d98eb0-d8fd-4f62-a3d3-051419dd921b' ||
      this.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17'
    ) {
      return Formatter.currency(this.value);
    }

    if (this.kpi === 'd5fa71f1-72f7-4a7c-8547-b1cd596377bf') {
      return Math.round(this.value);
    }
    return this.value;
  }

  get targetFormatted() {
    if (this.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17') {
      return Formatter.currency(this.target);
    }
    return this.target;
  }

  get value() {
    if (typeof this.kpiValue === 'string') {
      return this.kpiValue;
    }
    return this.kpiValue?.value || 0;
  }

  get target() {
    if (typeof this.kpiValue === 'string') {
      return 0;
    }
    return this.kpiValue?.target || 0;
  }

  get variant() {
    if (!this.target) {
      return 'soft-dark';
    }
    return this.colorIndicator;
  }

  get softColor() {
    const indicator = this.colorIndicator;
    if (this.kpi === 'd5fa71f1-72f7-4a7c-8547-b1cd596377bf') {
      if (indicator === 'primary') return 'badge-primary';
      if (indicator === 'warning') return 'badge-warning';
      if (indicator === 'danger') return 'badge-danger';
    }
    if (indicator === 'primary') return 'badge-soft-primary';
    if (indicator === 'warning') return 'badge-soft-warning';
    return 'badge-soft-danger';
  }

  get colorIndicator() {
    if (typeof this.value !== 'string') {
      const percentage = this.value / this.target;
      if (percentage >= 1) return 'primary';
      if (percentage >= 0.8) return 'warning';
      return 'danger';
    }
    return 'danger';
  }

  onBadgeClicked() {
    if (!this.hasKpiDetail) {
      return;
    }
    this.showKpiDetail = true;
  }
}
