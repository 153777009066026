
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { namespace } from 'vuex-class';

const kpiValuesService = new KpiValuesService();

const usersModule = namespace('users');

interface InsightsActiveUsers {
  name: string;
  hoodyId: string;
  amount: number;
  total: number;
}

interface InsightsInActiveUsers {
  usersAmount: number;
  dealAmount: number;
  dealTotalValue: number;
}

interface Insights {
  active: InsightsActiveUsers[];
  inActive: InsightsInActiveUsers;
}

// The @Component decorator indicates the class is a Vue component
@Component({
  computed: {
    Formatter() {
      return Formatter;
    },
  },
})
export default class ProgressCard extends Vue {
  isLoading = false;
  kpiValuesResult: KpiValueResult = {} as any;
  error: string = null;
  showPopover = false;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @usersModule.Getter('all')
  allUsers!: User[];

  @usersModule.Action('fetchAll')
  fetchAll!: () => Promise<User[]>;

  get title() {
    return this.uiComponent?.title;
  }

  get isYTD() {
    return this.uiComponent.isYTD;
  }

  kpiInfoTranslations() {
    if (this.uiComponent.kpi === '9ea398ac-ee1a-4678-99ff-250b46408cd1') {
      return this.$t('kpi.explain.new-deals');
    }
    if (this.uiComponent.kpi === '9c5b553e-7a78-4f2b-af6c-7f06cafc9198') {
      return this.$t('kpi.explain.won-deals');
    }

    if (this.uiComponent.kpi === 'b030691f-0e51-487e-bdd9-87d3ebabead8') {
      return this.$t('kpi.explain.1st-meetings');
    }

    if (this.uiComponent.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17') {
      return '';
    }
    return '';
  }

  toolTipValue(): Insights {
    if (this.isYTD) {
      let insights: Insights = {
        active: [],
        inActive: {
          usersAmount: 0,
          dealAmount: 0,
          dealTotalValue: 0,
        },
      };
      if (
        this.kpiValuesResult.kpi_values &&
        this.kpiValuesResult.kpi_values[0] &&
        this.kpiValuesResult.kpi_values[0].insights &&
        this.kpiValuesResult.kpi_values[0].info &&
        this.kpiValuesResult.kpi_values[0].info.length > 0
      ) {
        if (this.uiComponent.kpi === '34bbb284-3723-4953-ab63-485d85e0fa17') {
          let users = [];
          const inActiveUsers =
            this.kpiValuesResult.kpi_values[0].insights.inactive_users;
          const activeUser =
            this.kpiValuesResult.kpi_values[0].insights.active_users;
          const userNameMapping = new Map(
            this.allUsers.map((i) => [i.hoodyId, i.name]),
          );
          const dealsMapping = [];
          for (const deal of this.kpiValuesResult.kpi_values[0].info) {
            if (!deal.user) return insights;
            dealsMapping.push({
              hoodyId: deal.user[0],
              value: deal.value,
            });
          }
          for (const kpiUserId of activeUser) {
            const foundUser = userNameMapping.get(kpiUserId);
            const amount = dealsMapping.filter((i) => i.hoodyId === kpiUserId);
            let total = 0;
            amount.forEach((item) => {
              total = total + Number(item.value);
            });
            if (foundUser !== undefined) {
              users.push({
                name: foundUser,
                hoodyId: kpiUserId,
                amount: amount.length,
                total: total,
              });
            }
          }

          users = users.sort((a, b) => a.name.localeCompare(b.name));
          const inActiveUserStats = {
            usersAmount: inActiveUsers,
            dealAmount:
              this.kpiValuesResult.kpi_values[0].insights
                .won_deals_by_inactive_users,
            dealTotalValue:
              this.kpiValuesResult.kpi_values[0].insights
                .won_deal_value_by_inactive_users,
          };

          insights = {
            active: users,
            inActive: inActiveUserStats,
          };
          return insights;
        }
      } else {
        return insights;
      }
    }
  }

  get firstKpiValue() {
    if (
      this.kpiValuesResult?.kpi_values &&
      this.kpiValuesResult?.kpi_values.length > 0
    ) {
      return this.kpiValuesResult?.kpi_values[0];
    }
    return { value: 0, target: 0 };
  }

  get valueFormatted() {
    if (this.formatter) {
      return Formatter[this.formatter](this.value);
    }
    return this.value;
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get value() {
    return this.firstKpiValue.value;
  }

  get target() {
    return this.firstKpiValue.target;
  }

  get percentualProgress() {
    const change = (this.value / this.target) * 100;
    if (isNaN(change)) {
      return 0;
    }
    if (change === Infinity) {
      return 100;
    }
    return Math.round(change);
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  async loadData() {
    this.error = null;
    this.isLoading = true;
    try {
      await this.fetchAll();
      this.kpiValuesResult = await kpiValuesService.fetchKpiValuesForComponent(
        this.uiComponent,
        this.dateRange,
        this.teams,
        this.employeeHoodyIds,
        this.managers,
      );
    } catch (e) {
      this.error = `${e.message} - ${e.config.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
