import Vue from 'vue';
import Vuex from 'vuex';
import HoodyActivitiesService from '@/api/hoody-activities/hoody-activities.service';
import { Filter } from '@/shared/types/filter.class';
import StatisticsService from '@/api/statistics/statistics.service';

const activitiesService = new HoodyActivitiesService();
const statisticsService = new StatisticsService();

const state = {
  all: [],
  submissions: [],
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  submissions: (state: any) => {
    return state.submissions;
  },
};

const mutations = {
  setAll(state: any, activities: any[]) {
    Vue.set(state, 'all', state.all.concat(activities));
  },
  clearAll(state: any) {
    Vue.set(state, 'all', []);
  },
  setSubmissions(state: any, submissions: any[]) {
    Vue.set(state, 'submissions', submissions);
  },
};

const actions = {
  async fetchByUser(
    context: any,
    data: { user: string; to: Date; max: number },
  ): Promise<any[]> {
    const users = await activitiesService.fetchByUser(
      data.user,
      data.to,
      data.max,
    );
    context.commit('setAll', users);
    return users;
  },
  async getSurveySubmissionByUser(
    context: any,
    filter?: Filter,
  ): Promise<any[]> {
    try {
      const submissions = await statisticsService.fetchSurveySubmissions(
        filter,
      );
      context.commit('setSubmissions', submissions);
      return submissions;
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
