import { User } from '@/api/users/user.model';
import UsersService from '@/api/users/users.service';
import { Filter } from '@/shared/types/filter.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const usersService = new UsersService();

interface UserState {
  loggedInUser?: User;
  all: User[];
  current: User;
}

const state: UserState = {
  loggedInUser: null,
  all: [],
  current: new User(),
};

const getters = {
  loggedInUser: (state: UserState) => {
    return state.loggedInUser;
  },
  current: (state: UserState) => {
    return state.current;
  },
  all: (state: UserState) => {
    return state.all;
  },
};

const mutations = {
  setLoggedInUser(state: UserState, loggedInUser: User) {
    Vue.set(state, 'loggedInUser', loggedInUser);
  },
  setCurrent(state: UserState, user: User) {
    Vue.set(state, 'current', user);
  },
  setAll(state: UserState, users: User[]) {
    Vue.set(state, 'all', users);
  },
  clearLoggedInUser(state: UserState) {
    Vue.set(state, 'loggedInUser', null);
  },
};

const actions = {
  async fetchLoggedInUser(context: any) {
    let loggedInUser: User | undefined;
    try {
      const id = context.rootState.auth.currentLogin.userId;
      loggedInUser = await usersService.fetchByHoodyId(id);
      context.commit('setLoggedInUser', loggedInUser);
    } catch (e) {
      console.log(e);
    }
    return loggedInUser;
  },
  async fetchAll(context: any, payload?: Filter): Promise<User[]> {
    const users = await usersService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', users);
    return users;
  },
  async fetchById(context: any, id: string): Promise<User> {
    const user = await usersService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', user);
    return user;
  },
  async create(context: any, payload: User): Promise<User> {
    try {
      const user = await usersService.create(payload).catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
      context.commit('setCurrent', user);
      return user;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async reloadFromHoody(context: any, id: string): Promise<User> {
    try {
      const user = await usersService.reloadFromHoody(id).catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
      return user;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateById(context: any, payload: User): Promise<User> {
    const user = await usersService.update(payload._id, payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', user);
    return user;
  },
  async deleteById(context: any, id: string): Promise<User> {
    const user = await usersService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', user);
    return user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
