import { BaseModel } from '@/shared/types/base.model';

export class IssueReport extends BaseModel {
  constructor(
    public reporterId: string = '',
    public reporterName: string = '',
    public description: string = '',
    public info: any[] = [],
    public kpi: string = '',
    public record: any = null,
    public meta: any = {},
  ) {
    super();
  }
}
