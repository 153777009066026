import Vue from 'vue';
import VueGtagPlugin from 'vue-gtag';
import { Route } from 'vue-router';

Vue.use(VueGtagPlugin, {
  config: {
    id: 'G-V0TC7929NM',
    params: {
      userId: '',
    },
    pageTrackerTemplate(to: Route, from: Route) {
      console.log(to, from);
      return {
        pageTitle: to.name,
        pagePath: to.path,
        pageLocation: window.location.href,
        userId: to.meta?.userId,
      };
    },
  },
});
