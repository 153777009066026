import TranslationsService from '@/api/translations/translations.service';
import { Translation } from '@/api/translations/translation.model';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const translationsService = new TranslationsService();

interface TranslationState {
  current: Translation;
  all: Translation[];
}

const state: TranslationState = {
  current: new Translation(),
  all: [],
};

const getters = {
  current: (state: TranslationState) => {
    return state.current;
  },
  all: (state: TranslationState) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: TranslationState, translation: Translation) {
    Vue.set(state, 'current', translation);
  },
  setAll(state: TranslationState, translations: Translation[]) {
    Vue.set(state, 'all', translations);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Translation[]> {
    const translations = await translationsService
      .fetchAll(payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setAll', translations);
    return translations;
  },
  async fetchById(context: any, id: string): Promise<Translation> {
    const translation = await translationsService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', translation);
    return translation;
  },
  async create(context: any, payload: Translation): Promise<Translation> {
    const translation = await translationsService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', translation);
    return translation;
  },
  async updateById(context: any, payload: Translation): Promise<Translation> {
    const translation = await translationsService
      .update(payload._id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', translation);
    return translation;
  },
  async deleteById(context: any, id: string): Promise<Translation> {
    const translation = await translationsService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', translation);
    return translation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
