
import { Bar as BarChartGenerator } from 'vue-chartjs/legacy';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  Filler,
  ChartOptions,
  LinearScale,
  BarElement,
  PointElement,
  ChartEvent,
  ActiveElement,
  Tooltip,
  LineElement,
  LineController,
  Chart,
} from 'chart.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { isArray } from 'lodash';
import { Formatter } from '@/shared/util/formatter.class';

ChartJS.register(
  BarElement,
  LineElement,
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Legend,
  Tooltip,
  annotationPlugin,
);

@Component({
  components: {
    BarChartGenerator,
  },
})
export default class ProgressBarChart extends Vue {
  colors = [
    '#33B6A5',
    '#E63757',
    '#F09A19',
    '#AE6900',
    '#33B6A5',
    '#00D97E',
    '#EAF0CE',
    '#C0A9B0',
  ];

  @Prop()
  values!: { label: string; values: number[] }[];

  @Prop()
  labels!: string[];

  @Prop()
  remainings?: any[];

  @Prop()
  targets?: any[];

  @Prop()
  uiComponent!: CustomizableUIComponent;

  get chartData22() {
    const datasetArray = [];
    if (this.values.length > 0)
      datasetArray.push({
        label: this.$t('cards.current.values'),
        backgroundColor: '#33B6A5',
        data: this.values, // 40%, 30%, 20%
      });
    if (this.remainings.length > 0)
      datasetArray.push({
        label: this.$t('cards.remaining.values'),
        backgroundColor: 'rgba(51, 182, 165, 0.2)',
        data: this.remainings, // The remaining percentage (60%, 70%, 80%)
      });

    if (this.targets.length > 0)
      datasetArray.push({
        label: '',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        data: this.targets, // The remaining percentage (60%, 70%, 80%)
        hidden: true,
      });
    return {
      labels: this.labels,
      datasets: datasetArray,
    };
  }

  createAnnotationElement() {
    const x = {};
    if (this.targets.length === 0) {
      return {
        line1: {
          type: 'line',
          xMin: 0,
          xMax: 0,
          borderColor: 'rgba(0, 0, 255, 0)',
          borderWidth: 2,
        },
      };
    }
    this.targets.map((item, itemIndex) => {
      x[`annotation${itemIndex + 1}`] = {
        type: 'line',
        mode: 'vertical', // Change mode to 'horizontal'
        borderColor: 'black',
        borderWidth: 1,
        borderDash: [6, 6],
        label: {
          display: true,
          backgroundColor: 'black',
          borderRadius: 2,
          color: 'white',
          /*          content: (ctx) => {
                      return Formatter.intToReadableString(item);
                    },*/
        },
        xMin: item,
        xMax: item,
        yMax: (ctx) => {
          return itemIndex - 0.36;
        },
        yMin: (ctx) => {
          return itemIndex + 0.36;
        },
        z: 9999,
      };
    });
    return x;
  }

  get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value: any) {
              return Formatter.intToReadableString(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
      plugins: {
        annotation: {
          annotations: this.createAnnotationElement(),
        },
        tooltip: {
          enabled: true,
          mode: 'nearest',
          callbacks: {
            label: function (tooltipItems: any) {
              if (tooltipItems.dataset.type === 'line') {
                return `${tooltipItems.dataset.label}: ${tooltipItems.raw}`;
              }
              const dataIndex = tooltipItems.dataIndex;
              const formattedValue = Formatter['currency'](tooltipItems.raw);
              return `${
                tooltipItems.dataset.label
              } ${formattedValue} / ${Formatter['currency'](
                tooltipItems.chart._stacks['y.x.bar'][dataIndex][2],
              )}`;
            },
          },
        },
      },
    };
  }

  get datasets2() {
    return {
      labels: this.labels,
      datasets: [
        {
          data: [18, 12, 6, 9, 12, 3, 9],
          borderColor: [
            'rgba(255, 26, 104, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 0, 0, 0.2)',
          ],
          backgroundColor: [
            'rgba(255, 26, 104, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 0, 0, 1)',
          ],
          borderWidth: 1,
          borderSkipped: false,
          borderRadius: 5,
          barPercentage: 0.2,
          categoryPercentage: 0.8,
        },
      ],
    };
  }

  @Emit('click')
  handler(ctx: ChartEvent, elements: ActiveElement[]) {
    const firstElement = elements[0];
    if (!firstElement) {
      return;
    }
    return {
      kpiIndex: firstElement.datasetIndex,
      userIndex: firstElement.index,
    };
  }
}
