import Vue from 'vue';
import Vuex from 'vuex';
import { Integration } from '@/api/integrations/integration.model';
import IntegrationsService from '@/api/integrations/integrations.service';
import { Filter } from '@/shared/types/filter.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const integrationsService = new IntegrationsService();

const state = {
  current: new Integration(),
  all: [],
  pagination: {},
};

const getters = {
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, integration: Integration) {
    Vue.set(state, 'current', integration);
  },
  setAll(state: any, integrations: Integration) {
    Vue.set(state, 'all', integrations);
  },
};

const actions = {
  async fetchById(context: any, id: string) {
    try {
      const integration = await integrationsService.fetchOne(id);
      context.commit('setCurrent', integration);
      return integration;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },

  async fetchAll(context: any, payload?: Filter): Promise<Integration[]> {
    try {
      const integrations = await integrationsService.fetchAll(payload);
      context.commit('setAll', integrations);
      return integrations;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async delete(context: any, id: string): Promise<Integration> {
    try {
      return integrationsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, integration: Integration): Promise<Integration> {
    try {
      return await integrationsService.update(
        integration._id || '',
        integration,
      );
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, integration: Integration): Promise<Integration> {
    try {
      const result = await integrationsService.create(integration);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async ensureWebhooks(context: any, id: string): Promise<void> {
    try {
      const result = await integrationsService.ensureWebhooks(id);
      context.commit('setCurrent', result);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
