import axios from '@/plugins/axios';
import { Integration } from './integration.model';
import BaseService from '../base-service';
import { RecordType } from '../integration-records/record-type.enum';

export default class IntegrationsService extends BaseService<Integration> {
  get endpoint() {
    return 'ingest/integrations';
  }

  async testConnection(id: string): Promise<boolean> {
    const response = await axios.get<boolean>(
      `${this.endpoint}/${id}/test-connection`,
    );
    return response.data;
  }

  async getFieldsForEndpoint(
    id: string,
    endpointType: string,
  ): Promise<string[]> {
    const response = await axios.get<string[]>(
      `${this.endpoint}/${id}/fields/${endpointType}`,
    );
    return response.data;
  }

  async run(
    id: string,
    endpoints?: RecordType[],
    initialRun = false,
  ): Promise<boolean> {
    console.log(`${this.endpoint}/${id}/run`);
    const response = await axios.post<boolean>(`${this.endpoint}/${id}/run`, {
      integration_id: id,
      endpoints: endpoints,
      initialRun: initialRun,
    });
    return response.data;
  }

  async ensureWebhooks(id: string): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/${id}/ensure-webhooks`,
    );
    return response.data;
  }
}
