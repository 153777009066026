export class HoodyCompany {
  public '@type' = 'Company';
  public id?: string;
  public name: string;
  public language = 'en';
  public languageTerminology = 'corporate';
  public timeZone = 'Europe/Brussels';
  public emailProperties: {
    noreplyEmail: string;
    noreplyName?: string;
  } = {
    noreplyEmail: 'noreply@mg.salesnudge.ai',
  };

  public styleProperties: {
    largeLogo?: string;
    smallLogo?: string;
    loginBackgroundImage?: string;
    darkColorLogo?: boolean;
    stylesheet?: string;
    primaryColor?: string;
    secondaryColor?: string;
    backgroundColor?: string;
    titleTextColor?: string;
    secondaryTextColor?: string;
    linkTextColor?: string;
    progressTodoColor?: string;
    progressInProgressColor?: string;
    progressDoneColor?: string;
  } = {
    darkColorLogo: true,
  };

  public systemProperties: {
    loginAppUrl: string;
    supportEmail: string;
    systemName: string;
    autoLogin: boolean;
    defaultAuthenticator?: {
      id: string;
    };
  } = {
    loginAppUrl: 'https://app.salesnudge.ai',
    supportEmail: 'support@salesnudge.ai',
    systemName: 'salesnudge',
    autoLogin: false,
  };

  public translations: any[] = [];
  public properties: { [key: string]: any } = {};
  public viewRestrictionsEnabled: boolean;
  public directViewRestrictionsEnabled: boolean;
  public demo: boolean;
  public enabled = true;
}
