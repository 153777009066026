import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslationsService from '@/api/translations/translations.service';

Vue.use(VueI18n);
const translationsService = new TranslationsService();

const messages = {};

async function loadLanguageAsync() {
  const translations = await translationsService.translationsFile();
  for (const locale of Object.keys(translations)) {
    i18n.setLocaleMessage(locale, translations[locale]);
  }
}

loadLanguageAsync();

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
