
import {
  CustomizableUiKpiDetail,
  CustomizableUiKpiDetailField,
} from '@/api/customizable-uis/customizable-ui.model';
import { KpiDetailFieldType } from '@/api/customizable-uis/kpi-detail-field-type.enum';
import { KpiValue } from '@/api/kpi-values/kpi-value-result.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import { User } from '@/api/users/user.model';
import StaleCompaniesKpiDetails from '@/components/StaleCompaniesDetails.vue';

const customizableUIsModule = namespace('customizableUIs');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { StaleCompaniesKpiDetails },
})
export default class KpiDetailModal extends Vue {
  @VModel()
  data!: boolean;

  @Prop()
  kpiValue!: KpiValue;

  @Prop()
  kpi!: string;

  @Prop()
  user!: User | any;

  @Prop()
  kpiDetail!: CustomizableUiKpiDetail;

  @customizableUIsModule.Getter('kpiDetails')
  kpiDetails!: CustomizableUiKpiDetail[];

  getColumnClasses(index: number) {
    if (index === 0 || !this.kpiDetailFields?.length) {
      return '';
    }
    if (index === this.kpiDetailFields?.length - 1) {
      return 'text-right no-wrap';
    }
    return 'text-center no-wrap';
  }

  get kpiDetailFields() {
    return this.kpiDetail?.fields;
  }

  get kpiInsights() {
    return this.kpiValue?.insights.contacted_companies;
  }

  get kpiInfo() {
    return this.kpiValue?.info || [];
  }

  get name() {
    return this.user?.name;
  }

  get hasKpiDetail() {
    if (!this.kpiDetail || !this.kpiDetail.fields?.length) {
      return false;
    }
    return true;
  }

  isBadge(kpiDetailField: CustomizableUiKpiDetailField) {
    return kpiDetailField.type === KpiDetailFieldType.Badge;
  }

  isBadgeList(kpiDetailField: CustomizableUiKpiDetailField) {
    return kpiDetailField.type === KpiDetailFieldType.BadgeList;
  }

  isCRMLink(kpiDetailField: CustomizableUiKpiDetailField) {
    return kpiDetailField.type === KpiDetailFieldType.CRMLink;
  }

  getValue(info: unknown, kpiDetailField: CustomizableUiKpiDetailField) {
    const value = _.get(info, kpiDetailField.field);
    if (kpiDetailField.formatter) {
      return Formatter[kpiDetailField.formatter](value);
    }
    return value;
  }
}
