
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange, DateRangeEnum } from '../DateRangeSelect.vue';
import { DateUtil } from '@/shared/util/date.util';
import { namespace } from 'vuex-class';
import { Team } from '@/api/teams/team.model';

const kpiValuesService = new KpiValuesService();

const teamsModule = namespace('teams');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class TeamAvgComparisonCard extends Vue {
  isLoading = false;
  personalKpiValuesResult: KpiValueResult = {} as never;
  teamKpiValuesResult: KpiValueResult = {} as never;
  interval: any = {} as never;
  error: string = null;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({ required: true })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  @teamsModule.Getter('current')
  selectedTeam!: Team;

  get pluralInterval() {
    return this.numberOfIntervals > 1
      ? this.$t(`${this.dateRangeInterval}s`)
      : this.$t(this.dateRangeInterval);
  }

  get numberOfIntervals() {
    return this.uiComponent.numberOfIntervals || 4;
  }

  get numberOfWeeks() {
    return this.numberOfIntervals > 1 ? this.numberOfIntervals : '';
  }

  get dateRangeInterval() {
    this.dateRangeIntervalValue();
    return this.dateRange?.interval || 'weeks';
  }

  get cardInterval() {
    return `${this.numberOfIntervals} ${this.dateRangeInterval}`;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  valueFormattedPersonal(values: KpiValueResult) {
    let companySize =
      this.selectedTeam && this.selectedTeam.members.length > 0 ? 4 : 1;
    const value = Math.round(this.value(values) / companySize);
    if (this.formatter) {
      return Formatter[this.formatter](value);
    }
    return value;
  }

  valueFormatted(values: KpiValueResult) {
    let teamSize = this.selectedTeam ? this.selectedTeam.members.length * 4 : 1;
    const value = Math.round(this.value(values) / teamSize);
    if (this.formatter) {
      return Formatter[this.formatter](value);
    }
    return value;
  }

  dateRangeIntervalValue() {
    if (Object.values(DateRangeEnum).includes(this.dateRange?.interval)) {
      const dates = DateUtil.getStartEndOfIntervalFromIso(
        this.dateRange.start,
        this.numberOfIntervals,
        this.dateRange?.interval,
      );
      this.interval.start = dates.start;
      this.interval.stop = dates.end;
    }
  }

  value(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['value'];
  }

  firstKpiValue(value: KpiValueResult) {
    let total = 0;
    if (value?.kpi_values && value?.kpi_values.length > 0) {
      value?.kpi_values.forEach((val) => {
        total += val.value;
      });
      return { value: total, target: 0 };
    }
    return { value: 0, target: 0 };
  }

  target(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['target'];
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  get teamHoodyIDs() {
    let ids = [];
    if (this.selectedTeam && this.selectedTeam.members.length > 0) {
      ids = this.selectedTeam.members.map((e) => e.hoodyUser);
    }
    return ids;
  }

  getWindow() {
    if (this.dateRange?.interval === 'week') return 'W';
    if (this.dateRange?.interval === 'month') return 'M';
    if (this.dateRange?.interval === 'quarter') return 'Q';
    return 'W';
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.dateRangeIntervalValue();
      this.personalKpiValuesResult =
        await kpiValuesService.fetchKpiValuesForKpi(
          this.uiComponent.kpi,
          this.interval.start,
          this.interval.stop,
          [],
          this.employeeHoodyIds,
          [],
          this.getWindow(),
        );
      this.teamKpiValuesResult = await kpiValuesService.fetchKpiValuesForKpi(
        this.uiComponent.kpi,
        this.interval.start,
        this.interval.stop,
        [],
        this.teamHoodyIDs,
        [],
        this.getWindow(),
      );
    } catch (e) {
      console.error(e);
      this.error = `${e.message} - ${e.config.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
