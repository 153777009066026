
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { Prop, VModel, Watch } from 'vue-property-decorator';

export enum DateRangeEnum {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
}

export class DateRange {
  private _interval: DateRangeEnum;
  private _start?: string;
  private _stop?: string;

  constructor(
    interval: DateRangeEnum = DateRangeEnum.Week,
    start?: string,
    stop?: string,
  ) {
    this.interval = interval;
    if (start) {
      this.start = start;
    }

    if (stop) {
      this.stop = stop;
    }
  }

  public get interval() {
    return this._interval;
  }

  public set interval(val: DateRangeEnum) {
    this._interval = val;
    if (this.isCustom) {
      return;
    }
    if (this.isAllTime) {
      this._start = undefined;
      this._stop = undefined;
      return;
    }
    if (this.isLast12Months) {
      this._start = moment()
        .startOf('month')
        .subtract(12, 'months')
        .toISOString();
      this._stop = moment().endOf('month').toISOString();
      return;
    }
    this._start = moment(this._start)
      .startOf(val === DateRangeEnum.Week ? 'isoWeek' : (val as any))
      .toISOString();
    this._stop = moment(this._start)
      .endOf(val === DateRangeEnum.Week ? 'isoWeek' : (val as any))
      .toISOString();
  }

  public get start() {
    return this._start;
  }

  public set start(val: string) {
    this._start = val;
  }

  public get stop() {
    return this._stop;
  }

  public set stop(val: string) {
    this._stop = val;
  }

  public get isLast12Months() {
    return false;
    // return this._interval === DateRangeEnum.Last12Months;
  }

  public get isAllTime() {
    return false;
    // return this._interval === DateRangeEnum.AllTime;
  }

  public get isCustom() {
    return false;
    // return this._interval === DateRangeEnum.Custom;
  }

  updateInterval(offset: number, unit: string) {
    const newStart = moment(this.start)
      .add(offset as any, unit as any)
      .startOf(unit === DateRangeEnum.Week ? 'isoWeek' : (unit as any))
      .toISOString();

    const newStop = moment(newStart)
      .endOf(unit === DateRangeEnum.Week ? 'isoWeek' : (unit as any))
      .toISOString();

    this._start = newStart;
    this._stop = newStop;
  }

  previousInterval() {
    let unit = 'days';
    let offset = 1;
    if (this.isAllTime) {
      return;
    } else if (this.isCustom) {
      offset = moment(this._start).diff(this._stop, 'days');
    } else {
      unit = this._interval;
    }
    this.updateInterval(offset, unit);
  }

  nextInterval() {
    let unit = 'days';
    let offset = 1;
    if (this.isAllTime) {
      return;
    } else if (this.isCustom) {
      offset = moment(this._start).diff(this._stop, 'days');
    } else {
      unit = this._interval;
    }
    this.updateInterval(-offset, unit);
  }

  currentInterval() {
    const now = moment();
    let unit = 'days';
    let offset = now.diff(this._start, this._interval);
    if (this.isAllTime) {
      return;
    } else if (this.isCustom) {
      offset = moment(this._start).diff(this._stop, 'days');
    } else {
      unit = this._interval;
    }

    this.updateInterval(offset, unit);
  }
}

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class DateRangeSelect extends Vue {
  @VModel({
    type: Object,
    default: () => new DateRange(DateRangeEnum.Week),
  })
  data!: DateRange;

  @Prop()
  defaultInterval?: any;

  customTimeframe = '';

  get options() {
    const translatedValues = [];
    const values = Object.values(DateRangeEnum);
    for (const interval of values) {
      translatedValues.push({
        value: interval,
        label: this.$t(interval),
      });
    }
    return translatedValues;
  }

  handleIntervalChange(event: any) {
    const x = {
      week: 'week',
      month: 'month',
      quarter: 'quarter',
      maand: 'month',
      kwartaal: 'quarter',
    };
    this.data.interval = x[event];
  }

  get isAllTime() {
    return false;
    // return this.data.interval === DateRangeEnum.AllTime;
  }

  get isLast12Months() {
    return false;
    // return this.data.interval === DateRangeEnum.Last12Months;
  }

  get isCustom() {
    return false;
    // return this.data.interval === DateRangeEnum.Custom;
  }

  get rangeDisplay() {
    if (this.data.interval === DateRangeEnum.Week) {
      return moment(this.data.stop).format('[W]W YYYY');
    }
    if (this.data.interval === DateRangeEnum.Month) {
      const stop = moment(this.data.stop).format('MMM YYYY');
      return `${stop}`;
    }
    if (this.data.interval === DateRangeEnum.Quarter) {
      const stop = moment(this.data.stop).format('[Q]Q YYYY');
      return `${stop}`;
    }
    return '';
  }

  get customStart() {
    return this.customTimeframe.split(' to ')[0];
  }

  get customStop() {
    return this.customTimeframe.split(' to ')[1];
  }

  @Watch('data', { deep: true })
  onDataChangeSetRouteQuery() {
    this.$router.replace({
      name: this.$route.name,
      params: this.$route.params,
      query: {
        ...this.$route.query,
        start: this.data.start,
        stop: this.data.stop,
        interval: this.data.interval,
      },
    });
  }

  @Watch('$route.query', { deep: true })
  onQueryParamsChanged() {
    this.restoreDateRangeFromQueryParams();
  }

  @Watch('data.interval')
  onIntervalChanged() {
    if (!this.data.isCustom) {
      return;
    }
    this.customTimeframe = `${moment(this.data.start).format(
      'YYYY-MM-DD',
    )} to ${moment(this.data.stop).format('YYYY-MM-DD')}`;
  }

  @Watch('customTimeframe')
  onCustomTimeframeChanged() {
    this.data.start = moment(this.customStart).toISOString();
    this.data.stop = moment(this.customStop).toISOString();
  }

  nextInterval() {
    this.data.nextInterval();
  }

  previousInterval() {
    this.data.previousInterval();
  }

  toCurrentInterval() {
    this.data.currentInterval();
  }

  restoreDateRangeFromQueryParams() {
    if (this.$route.query.start) {
      this.data.start = this.$route.query.start as string;
    }
    if (this.$route.query.stop) {
      this.data.stop = this.$route.query.stop as string;
    }
    if (this.$route.query.interval) {
      this.data.interval = this.$route.query.interval as DateRangeEnum;
    } else {
      this.data.interval = this.defaultInterval;
    }
  }

  created() {
    this.restoreDateRangeFromQueryParams();
  }
}
