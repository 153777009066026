
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { DateRange } from '@/components/DateRangeSelect.vue';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import DealSourceService from '@/api/deal-source/deal-source.service';
import DealSourcePerformanceColumn from '@/components/DealSourcePerformanceColumn.vue';
import DealSourceKpiValueBadgeColumn from '@/components/DealSourceKpiValueBadgeColumn.vue';
import { DealSources } from '@/api/kpi-values/kpi-value-result.model';

const kpiValuesService = new KpiValuesService();
const dealSourceService = new DealSourceService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { DealSourcePerformanceColumn, DealSourceKpiValueBadgeColumn },
})
export default class DealSourcePerformanceCard extends Vue {
  isLoading = false;
  dealSourceResults: any[] = [];
  dealSources: DealSources;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  get title() {
    return this.uiComponent?.title;
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get kpis() {
    return this.uiComponent?.kpis;
  }

  get values() {
    return this.dealSourceResults;
  }

  get sources() {
    return this.uiComponent.selectedDealSources;
  }

  getKpiValue(source: any, kpi: string) {
    for (const kvr of this.values) {
      const result = kvr?.kpi_values?.find((kpiValue) => {
        return kpiValue.source_id === source?.id && kpiValue.kpi_id === kpi;
      });
      if (result) {
        return result;
      }
    }
  }

  async loadData() {
    this.isLoading = true;
    try {
      const ids = this.uiComponent.selectedDealSources.map(
        (item: any) => item.id,
      );
      this.dealSourceResults =
        await kpiValuesService.fetchKpiValuesSourceForComponent(
          this.uiComponent,
          this.dateRange,
          ids,
        );
    } finally {
      this.isLoading = false;
    }
  }
}
