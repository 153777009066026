import axios from '@/plugins/axios';
import { Filter } from '@/shared/types/filter.class';
import BaseService from '@/api/base-service';
import { AppConfig } from '@/config';

export default class StatisticsService extends BaseService<any> {
  get endpoint() {
    return 'statistics';
  }

  async fetchSurveyForCompany(filter?: Filter): Promise<any> {
    const url = 'activities/surveys-by-company';
    const response = await axios.get<any>(url, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchSurveySubmissions(filter?: Filter): Promise<any> {
    const url = 'activities/employee-survey-submissions';
    const response = await axios.get<any>(url, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchTotalRevenue(filter?: Filter): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/total-revenue`, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchCurrentSituation(filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/current-situation`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchSalesnudgeIndex(filter?: Filter): Promise<any[]> {
    const response = await axios.get<any>(
      `${this.endpoint}/salesnudge-indexes`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchKpiSummary(filter?: Filter): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/kpi-summary`, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchCompanyAvg(weekCountToExtract: number): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/company-average`, {
      params: { weekCountToExtract },
    });
    return response.data;
  }

  async fetchKpiValues(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/kpi/${kpi}`, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchKpiValuesPerUser(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-user-based/${kpi}`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchSingleKpiValue(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-single-value/${kpi}`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchKpiValuesNew(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/kpi/${kpi}/new`, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchPipeline(filter?: Filter): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/pipeline`, {
      params: filter?.toParams(),
    });
    return response.data;
  }

  async fetchKpiValuesPerUserNew(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-user-based/${kpi}/new`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchSingleKpiValueNew(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-single-value/${kpi}/new`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchKpiValuesForUser(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-for-user/${kpi}`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }

  async fetchKpiValuesForUserNew(kpi: string, filter?: Filter): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/kpi-for-user/${kpi}/new`,
      {
        params: filter?.toParams(),
      },
    );
    return response.data;
  }
}
