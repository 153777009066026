import { BaseModel } from '@/shared/types/base.model';

export class Log extends BaseModel {
  constructor(
    public record?: string,
    public type?: string,
    public data = {},
    public externalId?: string,
    public integration?: string,
  ) {
    super();
  }
}
