import KpisService from '@/api/kpis/kpis.service';
import { Kpi } from '@/api/kpis/kpi.model';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const kpisService = new KpisService();

interface KpiState {
  all: Kpi[];
}

const state: KpiState = {
  all: [],
};

const getters = {
  all: (state: KpiState) => {
    return state.all;
  },
};

const mutations = {
  setAll(state: KpiState, kpis: Kpi[]) {
    Vue.set(state, 'all', kpis);
  },
};

const actions = {
  async fetchAll(context: any, company: string): Promise<Kpi[]> {
    const filter = new Filter({ company: company });
    const kpis = await kpisService.fetchAll(filter).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', kpis);
    return kpis;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
