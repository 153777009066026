import { SortOrder } from './sort-order.enum';

export class Filter {
  constructor(
    public query: { [key: string]: unknown } = {},
    public sort?: { [key: string]: SortOrder },
    public populate?: string[],
  ) {}

  setSearch(value?: string) {
    if (!this.query) {
      this.query = {};
    }
    if (value === '') {
      delete this.query.$search;
    }
    this.query.$search = value;
  }

  setSort(field: string) {
    if (!this.sort) {
      this.sort = {};
    }
    const currentOrder = this.sort[field];
    this.sort = {};
    if (!currentOrder || currentOrder === SortOrder.Desc) {
      this.sort[field] = SortOrder.Asc;
    } else if (currentOrder === SortOrder.Asc) {
      this.sort[field] = SortOrder.Desc;
    }
  }

  private sortToArray() {
    if (!this.sort) {
      return;
    }

    const result = [];

    for (const [key, value] of Object.entries(this.sort)) {
      result.push(`${key},${value}`);
    }
    return result;
  }

  toParams() {
    return {
      ...this.query,
      sort: this.sort,
      populate: this.populate?.join(' '),
    };
  }
}
