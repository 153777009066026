import { BaseModel } from '@/shared/types/base.model';

export class Translation extends BaseModel {
  constructor(
    public locale: string = 'en',
    public key: string = '',
    public value: string = '',
  ) {
    super();
  }
}
