import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowsAltH,
  faArrowUp,
  faBullseye,
  faChartBar,
  faChartLine,
  faClipboard,
  faClock,
  faCogs,
  faEuroSign,
  faExchangeAlt,
  faNetworkWired,
  faSignOutAlt,
  faStopwatch,
  faUserFriends,
  faRandom,
  faBug,
  faEdit,
  faUserNinja,
  faChartSimple,
  faBuilding,
  faList,
  faEnvelope,
  faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faList,
  faBuilding,
  faChartSimple,
  faEdit,
  faUserNinja,
  faBug,
  faStopwatch,
  faRandom,
  faUserFriends,
  faChartLine,
  faChartBar,
  faBullseye,
  faClock,
  faCogs,
  faArrowsAltH,
  faSignOutAlt,
  faEuroSign,
  faClipboard,
  faExchangeAlt,
  faNetworkWired,
  faArrowUp,
  faArrowDown,
  faEnvelope,
  faEnvelopeOpen,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
