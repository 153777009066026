
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';
import SparklineChart from '@/components/SparklineChart.vue';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import { DateTime } from 'luxon';

const kpiValuesService = new KpiValuesService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    SparklineChart,
  },
})
export default class ProgressCard extends Vue {
  isLoading = false;
  kpiValuesResult: KpiValueResult = new KpiValueResult();
  historyKpiValuesResult: KpiValueResult = new KpiValueResult();
  error: string = null;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  get title() {
    return this.uiComponent?.title;
  }

  /*
    get isYTD() {
      return this.uiComponent.isYTD;
    }
  */

  kpiInfoTranslations() {
    if (this.uiComponent.kpi === '9ea398ac-ee1a-4678-99ff-250b46408cd1') {
      return this.$t('kpi.explain.new-deals');
    }
    if (this.uiComponent.kpi === '9c5b553e-7a78-4f2b-af6c-7f06cafc9198') {
      return this.$t('kpi.explain.won-deals');
    }
    if (this.uiComponent.kpi === 'b030691f-0e51-487e-bdd9-87d3ebabead8') {
      return this.$t('kpi.explain.1st-meetings');
    }
  }

  get valueFormatted() {
    if (!this.formatter) {
      return this.value;
    }
    return Formatter[this.formatter](this.value);
  }

  get value() {
    if (!this.kpiValuesResult) {
      return 0;
    }
    let sum = 0;
    for (const kpiValue of this.kpiValuesResult.kpi_values) {
      sum += kpiValue.value;
    }
    return sum;
  }

  get historicalValues() {
    const x = this.historyKpiValuesResult?.kpi_values?.map((kv) =>
      Number(kv.value.toFixed(2)),
    );
    if (x.length === 0) return [0, 0, 0, 0, 0];
    return x;
  }

  get percentualChangeRounded() {
    return this.percentualChange.toFixed(0);
  }

  get percentualChange() {
    if (!this.historicalValues || this.historicalValues.length < 2) {
      return 0;
    }
    const currentValue =
      this.historicalValues[this.historicalValues.length - 1];
    const previousValue =
      this.historicalValues[this.historicalValues.length - 2];
    const change = ((currentValue - previousValue) / previousValue) * 100;
    if (isNaN(change)) {
      return 0;
    }
    if (change === Infinity) {
      return 100;
    }
    return change;
  }

  get percentualChangeVariant() {
    if (this.percentualChange > 0) {
      return 'success';
    }
    if (this.percentualChange < 0) {
      return 'danger';
    }
    return 'primary';
  }

  get employeeHoodyIds() {
    if (this.employees && this.employees[0] && this.employees[0].hoodyId) {
      return this.employees?.map((e) => e.hoodyId);
    } else if (this.teams && this.teams[0] && this.teams[0]['members']) {
      return this.teams?.map((e) => {
        return e['members'].map((item) => item.hoodyUser);
      });
    }
    return [];
  }

  get teamIds() {
    if (this.teams) {
      if (this.teams.length > 0) {
        return this.teams.map((item: any) => item._id);
      }
    }
    return [];
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  getWindow() {
    if (this.dateRange?.interval === 'week') return 'W';
    if (this.dateRange?.interval === 'month') return 'M';
    if (this.dateRange?.interval === 'quarter') return 'Q';
    return 'W';
  }

  get newStartForHistory() {
    if (this.dateRange?.interval === 'week') {
      return DateTime.fromISO(this.dateRange.start)
        .minus({ week: 4 })
        .toISO({ includeOffset: false });
    }
    if (this.dateRange?.interval === 'month') {
      return DateTime.fromISO(this.dateRange.start)
        .minus({ month: 4 })
        .toISO({ includeOffset: false });
    }
    if (this.dateRange?.interval === 'quarter') {
      return DateTime.fromISO(this.dateRange.stop)
        .minus({ quarters: 4 })
        .toISO({ includeOffset: false });
    }
    return DateTime.fromISO(this.dateRange.start)
      .minus({ week: 4 })
      .toISO({ includeOffset: false });
  }

  async loadData() {
    this.error = null;
    this.isLoading = true;
    try {
      this.kpiValuesResult = await kpiValuesService.fetchKpiValuesForKpi(
        this.uiComponent.kpi,
        DateTime.fromISO(this.dateRange.start).toISO({ includeOffset: false }),
        DateTime.fromISO(this.dateRange.stop).toISO({ includeOffset: false }),
        this.teamIds,
        this.employeeHoodyIds,
        this.managers,
      );
      const newStart = this.newStartForHistory;
      this.historyKpiValuesResult = await kpiValuesService.fetchKpiValuesForKpi(
        this.uiComponent.kpi,
        newStart,
        DateTime.fromISO(this.dateRange.stop).toISO({ includeOffset: false }),
        this.teamIds,
        this.employeeHoodyIds,
        this.managers,
        this.getWindow(),
      );
    } catch (e) {
      this.error = `${e.message} - ${e.config?.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
