import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { User } from '../users/user.model';
import { Team } from './team.model';

export default class TeamsService extends BaseService<Team> {
  get endpoint() {
    return 'teams';
  }

  async fetchManagedUsers(): Promise<User[]> {
    const response = await axios.get<User[]>(`${this.endpoint}/users`, {});

    return response.data;
  }
}
