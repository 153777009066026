/* eslint-disable */
export default {
  name: '{{{name}}}',
  type: 'TEAMLEADER',
  auth: {
    endpoint: 'https://app.teamleader.eu/api/getUsers.php',
    body: {
      api_secret: '{{{apiSecret}}}',
      api_group: '{{{apiGroup}}}',
    },
  },
  endpoints: [
    {
      type: 'CONTACT',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getContacts.php',
      filter: [],
    },
    {
      type: 'TASK',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getTasks.php',
      filter: [],
    },
    {
      type: 'TASK_TYPE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getTaskTypes.php',
      filter: [],
    },
    {
      type: 'OPPORTUNITY',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getDeals.php',
      filter: [],
    },
    {
      type: 'USER',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getUsers.php',
      filter: [],
    },
    {
      type: 'COMPANY',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getCompanies.php',
      filter: [],
    },
    {
      type: 'CALL',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getCalls.php',
      filter: [],
    },
    {
      type: 'MEETING',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getMeetings.php',
      filter: [],
    },
    {
      type: 'DEAL_PHASE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getDealPhases.php',
      filter: [],
    },
    {
      type: 'DEAL_SOURCE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getDealSources.php',
      filter: [],
    },
    {
      type: 'MEETING_PHASE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getDealSources.php',
      filter: [],
    },
    {
      type: 'NOTE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getNotes.php',
      filter: [],
    },
    {
      type: 'QUOTATION',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/quotations.list',
    },
    {
      type: 'FILE',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getFiles.php',
    },
    {
      type: 'AGENDA',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'AGENDA_SCOPED',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/events.list',
    },
    {
      type: 'ACTIVITY_TYPES',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/activityTypes.list',
    },
    {
      type: 'CUSTOM_FIELD',
      idField: 'id',
      url: 'https://app.teamleader.eu/api/getCustomFields.php',
    },
    {
      type: 'PROJECT',
      idField: 'id',
      url: 'https://api.focus.teamleader.eu/projects-v2/projects.list',
    },
  ],
};
