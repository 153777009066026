import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.store';
import app from './modules/app.store';
import users from './modules/users.store';
import integrations from './modules/integrations.store';
import integrationRecords from './modules/integration-records.store';
import logs from './modules/logs.store';
import integrationRuns from './modules/integration-runs.store';
import companies from './modules/companies.store';
import hoodyUsers from './modules/hoody-users.store';
import statistics from './modules/statistics.store';
import nudges from './modules/nudges.store';
import targets from './modules/targets.store';
import hoodyActivities from './modules/hoody-activities.store';
import kpiConfig from './modules/kpi-config.store';
import customizableUIs from './modules/customizable-uis.store';
import issueReports from './modules/issue-reports.store';
import hoodyCompanies from './modules/hoody-companies.store';
import kpis from './modules/kpis.store';
import teams from './modules/teams.store';
import translations from './modules/translations.store';
import pipelines from './modules/pipelines.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    app,
    users,
    integrations,
    integrationRecords,
    integrationRuns,
    logs,
    companies,
    hoodyUsers,
    statistics,
    targets,
    nudges,
    hoodyActivities,
    kpiConfig,
    customizableUIs,
    issueReports,
    hoodyCompanies,
    kpis,
    teams,
    translations,
    pipelines,
  },
});
