import { Phase } from '../phases/phase.model';
import { DealState } from './deal-state.enum';

export class PipelineDetail {
  public phase?: Phase = new Phase();
  public deal_state: DealState;
  public phase_id?: string;
  public order?: number;
}

export class Pipeline {
  public id: string;
  public name: string;
  public integration_id: string;
  public integration: { id?: string } = {};
  public enabled: boolean;
  public phases: PipelineDetail[] = [];
}
