import BaseService from '@/api/base-service';
import axios from '@/plugins/axios';

export default class DealSourceService extends BaseService<any> {
  get endpoint() {
    return 'deal-sources';
  }

  async getDealSourceFromCompanyId(companyId?: string) {
    let url = `${this.endpoint}/all`;
    if (companyId) {
      url = url + `/${companyId}`;
    }
    const response: any = await axios.get<any>(url);
    return response.data;
  }
}
