import {
  CustomizableUI,
  CustomizableUIComponent,
  CustomizableUIComponentSizing,
} from '@/api/customizable-uis/customizable-ui.model';
import CustomizableUIsService from '@/api/customizable-uis/customizable-uis.service';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const customizableUIsService = new CustomizableUIsService();

interface CustomizableUIStateInterface {
  current: CustomizableUI;
}

const state: CustomizableUIStateInterface = {
  current: new CustomizableUI(),
};

const getters = {
  current: (state: CustomizableUIStateInterface) => {
    return state.current;
  },
  generalDashboard: (state: CustomizableUIStateInterface) => {
    return state.current.generalDashboard;
  },
  teamDashboard: (state: CustomizableUIStateInterface) => {
    return state.current.teamDashboard;
  },
  employeeDashboard: (state: CustomizableUIStateInterface) => {
    return state.current.employeeDashboard;
  },
  employeeReportDashboard: (state: CustomizableUIStateInterface) => {
    return state.current.employeeReportDashboard;
  },
  benchmarkDashboard: (state: CustomizableUIStateInterface) => {
    return state.current.benchmarkDashboard;
  },
  kpiDetails: (state: CustomizableUIStateInterface) => {
    return state.current.kpiDetails;
  },
  window: (state: CustomizableUIStateInterface) => {
    return state.current.window;
  },
  ytdStartMonth: (state: CustomizableUIStateInterface) => {
    return state.current.ytdStartMonth;
  },
};

const mutations = {
  setCurrent(state: CustomizableUIStateInterface, ui: CustomizableUI) {
    Vue.set(state, 'current', ui);
  },
};

const actions = {
  async fetchForLoggedInUser(context: any): Promise<CustomizableUI> {
    const ui = await customizableUIsService
      .fetchForLoggedInUser()
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', ui);
    return ui;
  },
  async fetchForCompany(
    context: any,
    company: string,
  ): Promise<CustomizableUI> {
    const ui = await customizableUIsService
      .fetchForCompany(company)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', ui);
    return ui;
  },

  async updateById(
    context: any,
    payload: CustomizableUI,
  ): Promise<CustomizableUI> {
    const customizableUI = await customizableUIsService
      .update(payload._id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', customizableUI);
    return customizableUI;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
