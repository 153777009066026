
import Vue from 'vue';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { Prop, Watch } from 'vue-property-decorator';
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { User } from '@/api/users/user.model';
import { DateRange } from '@/components/DateRangeSelect.vue';
import Component from 'vue-class-component';
import EmployeeProgressionChart from '@/components/employeeProgressionChart.vue';
import WeightedPipelineChart from '@/components/WeightedPipelineChart.vue';
import { DateUtil } from '@/shared/util/date.util';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import { Team } from '@/api/teams/team.model';

const kpiValuesService = new KpiValuesService();

@Component({
  components: {
    WeightedPipelineChart,
    EmployeeProgressionChart,
  },
})
export default class EmployeeProgressionCard extends Vue {
  isLoading = false;
  kpiValuesResult: KpiValueResult;
  error: string = null;
  @Prop({ required: false })
  dateRange!: DateRange;
  componentKey = 0;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Prop()
  teams!: Team[];

  @Prop()
  employees!: User[];

  get title() {
    return this.uiComponent?.title;
  }

  get team() {
    return this.teams && this.teams.length !== 0
      ? this.teams.map((e) => e.id)
      : [''];
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  getWindow() {
    if (this.dateRange?.interval === 'week') return 'W';
    if (this.dateRange?.interval === 'month') return 'M';
    if (this.dateRange?.interval === 'quarter') return 'Q';
    return 'W';
  }

  async fetchKpiResult() {
    this.error = null;
    const start = DateUtil.fromISOToISOIntervalDurationAgo(
      this.dateRange.stop,
      13,
      this.dateRange.interval,
    );
    const stop = DateUtil.removeTimezoneFromISO(this.dateRange.stop);
    this.kpiValuesResult = await kpiValuesService.fetchKpiValuesForKpi(
      this.uiComponent.kpi,
      start,
      stop,
      [],
      this.employeeHoodyIds,
      [],
      this.getWindow(),
    );
    this.componentKey++;
  }

  async loadData() {
    this.isLoading = true;
    try {
      await this.fetchKpiResult();
    } catch (e) {
      this.error = `${e.message}`;
    } finally {
      this.isLoading = false;
    }
  }
}
