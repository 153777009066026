import HoodyUsersService from '@/api/hoody-users/hoody-users.service';
import { HoodyUser } from '@/api/hoody-users/hoody-user.model';
import Vue from 'vue';

const hoodyUsersService = new HoodyUsersService();

interface HoodyUserState {
  all: HoodyUser[];
}

const state: HoodyUserState = {
  all: [],
};

const getters = {
  all: (state: HoodyUserState) => {
    return state.all;
  },
};

const mutations = {
  setAll(state: HoodyUserState, users: HoodyUser[]) {
    Vue.set(state, 'all', users);
  },
};

const actions = {
  async fetchAll(context: any, company: string): Promise<HoodyUser[]> {
    const users = await hoodyUsersService.fetchByCompany(company);
    context.commit('setAll', users);
    return users;
  },
  // async fetchAllForHoodyCompany(context: any, hoodyCompany: string): Promise<HoodyUser[]> {
  //   const users = await hoodyUsersService.fetchByHooCompany(hoodyCompany);
  //   context.commit('setAll', users);
  //   return users;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
