import { BaseModel } from '@/shared/types/base.model';
import { RecordType } from '../integration-records/record-type.enum';

class Endpoint {
  type: RecordType;
  idField: string;
  method: string;
  url: string;
  filter: any;
}

class AuthDetail {
  endpoint: string;
  newApiCoupled: boolean;
  body: any;
  tokenField: string;
}

class IntegrationMeta {
  webhook_set: boolean;
  latest_sync: { [key: string]: string };
  base_url: string;
  excluded_record_types: string[];
}

export class Integration extends BaseModel {
  constructor(
    public name?: string,
    public type?: string,
    public endpoints: Endpoint[] = [],
    public auth: AuthDetail = new AuthDetail(),
    public company?: string,
    public meta?: IntegrationMeta,
    public subDomain?: string,
  ) {
    super();
  }
}
