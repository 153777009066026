
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import { User } from '@/api/users/user.model';
import { Formatter } from '@/shared/util/formatter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import { DateUtil } from '@/shared/util/date.util';
import { DateRangeEnum } from '@/components/DateRangeSelect.vue';

const kpiValuesService = new KpiValuesService();

// The @Component decorator indicates the class is a Vue component
@Component({
  computed: {
    DateRangeEnum() {
      return DateRangeEnum;
    },
  },
})
export default class TeamAvgComparisonCardSingleWeek extends Vue {
  isLoading = false;
  personalKpiValuesResult: KpiValueResult = {} as never;
  teamKpiValuesResult: KpiValueResult = {} as never;
  interval: any = {} as never;
  error: string = null;

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({ required: true })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  formatter!: string;

  @Prop()
  company!: string;

  @Prop()
  teams!: string[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  get numberOfIntervals() {
    return 1;
  }

  get dateRangeInterval() {
    this.dateRangeIntervalValue();
    return this.$t(this.dateRange?.interval || 'weeks');
  }

  get cardInterval() {
    return `${this.numberOfIntervals} ${this.dateRangeInterval}`;
  }

  get employeeHoodyIds() {
    return this.employees?.map((e) => e.hoodyId);
  }

  firstKpiValue(value: KpiValueResult) {
    if (value?.kpi_values && value?.kpi_values.length > 0) {
      return value?.kpi_values[0];
    }
    return { value: 0, target: 0 };
  }

  valueFormatted(values: KpiValueResult) {
    if (this.formatter) {
      return Formatter[this.formatter](this.value(values));
    }
    return this.value(values);
  }

  dateRangeIntervalValue() {
    if (Object.values(DateRangeEnum).includes(this.dateRange?.interval)) {
      const dates = DateUtil.getStartEndOfIntervalFromIso(
        this.dateRange.start,
        this.numberOfIntervals,
        this.dateRange?.interval,
      );
      this.interval.start = dates.start;
      this.interval.stop = dates.end;
    }
  }

  value(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['value'];
  }

  target(values: KpiValueResult) {
    const kpi = this.firstKpiValue(values);
    return kpi['target'];
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams')
  onTeamsChanged() {
    this.loadData();
  }

  @Watch('employees')
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('company')
  onCompanyChanged() {
    this.loadData();
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.personalKpiValuesResult =
        await kpiValuesService.fetchKpiValuesForComponent(
          this.uiComponent,
          this.dateRange,
          this.teams,
          this.employeeHoodyIds,
          this.managers,
        );
      this.teamKpiValuesResult =
        await kpiValuesService.fetchKpiValuesForComponent(
          this.uiComponent,
          this.dateRange,
          this.teams,
          [],
          this.managers,
        );
    } catch (e) {
      console.error(e);
      this.error = `${e.message} - ${e.config.url}`;
    } finally {
      this.isLoading = false;
    }
  }
}
