import { DateTime, DateTimeUnit, Duration } from 'luxon';
import { DurationLike } from 'luxon/src/duration';

export class DateUtil {
  static fromISOToLocaleWithTime(iso: string, defaultValue = '') {
    const datetime: DateTime = DateTime.fromISO(iso);
    if (!datetime.isValid) {
      return defaultValue;
    }
    return datetime.toFormat('dd LLL yyyy HH:mm');
  }

  static fromISOToISO12MonthsAgo(iso: string) {
    const datetime: DateTime = DateTime.fromISO(iso).minus({ months: 12 });
    return datetime.toISO({ includeOffset: false });
  }

  static fromISOSubtractDurationInterval(
    iso: string,
    interval: number,
    duration: string,
  ) {
    const x: DurationLike = {};
    x[`${duration}s`] = interval;
    const datetime: DateTime = DateTime.fromISO(iso).minus(x);
    return datetime.toISO({ includeOffset: false });
  }

  static fromISOSubtractDurationIntervalEndOfInterval(
    iso: string,
    interval: number,
    duration: string,
  ) {
    const x: DurationLike = {};
    x[`${duration}s`] = interval;
    const datetime: DateTime = DateTime.fromISO(iso)
      .minus(x)
      .endOf(duration as any);
    return datetime.toISO({ includeOffset: false });
  }

  static fromISOSubtractDurationIntervalStartOfInterval(
    iso: string,
    interval: number,
    duration: string,
  ) {
    const x: DurationLike = {};
    x[`${duration}s`] = interval;
    const datetime: DateTime = DateTime.fromISO(iso)
      .minus(x)
      .startOf(duration as any);
    return datetime.toISO({ includeOffset: false });
  }

  static fromISOToISOIntervalDurationAgo(
    iso: string,
    interval: number,
    duration: string,
  ) {
    const x: DurationLike = {};
    x[`${duration}s`] = interval;
    const datetime: DateTime = DateTime.fromISO(iso).minus(x);
    return datetime.toISO({ includeOffset: false });
  }

  static fromISOSubtractIntervals(iso: string, amount: number) {
    const datetime: DateTime = DateTime.fromISO(iso).minus({ weeks: amount });
    return datetime.toISO({ includeOffset: false });
  }

  static removeTimezoneFromISO(iso: string) {
    const datetime: DateTime = DateTime.fromISO(iso);
    return datetime.toISO({ includeOffset: false });
  }

  static getStartEndOfIntervalFromIso(
    iso: string,
    interval: number,
    dateRange: string,
  ) {
    const endDataTime = DateTime.fromISO(iso)
      .startOf(<DateTimeUnit>dateRange)
      .minus({ days: 1 })
      .toISO({ includeOffset: false });
    const start = DateTime.fromISO(iso)
      .startOf(<DateTimeUnit>dateRange)
      .minus({ days: 1 });
    const duration: DurationLike = {};
    duration[`${dateRange}s`] = interval;
    const startDateTime = start
      .minus(duration)
      .plus({ days: 1 })
      .toISO({ includeOffset: false });
    return { start: startDateTime, end: endDataTime };
  }
}
