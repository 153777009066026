import TargetsService from '@/api/targets/targets.service';
import { Target } from '@/api/targets/target.model';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';

const targetsService = new TargetsService();

interface TargetState {
  current: Target;
  all: Target[];
}

const state: TargetState = {
  current: new Target('', ''),
  all: [],
};

const getters = {
  current: (state: TargetState) => {
    return state.current;
  },
  all: (state: TargetState) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: TargetState, target: Target) {
    Vue.set(state, 'current', target);
  },
  setAll(state: TargetState, targets: Target[]) {
    Vue.set(state, 'all', targets);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Target[]> {
    const targets = await targetsService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', targets);
    return targets;
  },
  async fetchById(context: any, id: string): Promise<Target> {
    const target = await targetsService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', target);
    return target;
  },
  async create(context: any, payload: Target): Promise<Target> {
    const target = await targetsService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', target);
    return target;
  },
  async updateById(context: any, payload: Target): Promise<Target> {
    const target = await targetsService
      .update(payload._id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', target);
    return target;
  },
  async deleteById(context: any, id: string): Promise<Target> {
    const target = await targetsService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', target);
    return target;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
