
import { User } from '@/api/users/user.model';
import { ColorUtils } from '@/shared/util/color.util';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class UserTeamPerformanceColumn extends Vue {
  @Prop()
  user!: User;

  @Prop()
  index!: number;

  get name() {
    return this.user?.name;
  }

  get thumbUri() {
    return this.user?.hoodyUser?.thumbUri;
  }

  get color() {
    return ColorUtils.getUniqueColorForIndex(this.index, this.userId);
  }

  get userId() {
    return this.user?._id;
  }
}
