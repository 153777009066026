import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import store from '../store';
import { User } from '@/api/users/user.model';
import { AxiosError } from 'axios';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'GeneralDashboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "generaldashboard" */ '../views/GeneralDashboard.vue'
      ),
  },
  {
    path: '/team',
    name: 'TeamDashboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "teamdashboard" */ '../views/TeamDashboard.vue'
      ),
  },
  {
    path: '/benchmark',
    name: 'BenchmarkDashboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "BenchmarkDashboard" */ '../views/BenchmarkDashboard.vue'
      ),
  },
  {
    path: '/employee/:id?',
    name: 'EmployeeDashboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "employeedashboard" */ '../views/EmployeeDashboard.vue'
      ),
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Users.vue'),
  },
  {
    path: '/users/new',
    name: 'NewUser',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/UserDetail.vue'),
  },

  {
    path: '/users/:id',
    name: 'UserDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/UserDetail.vue'),
  },

  {
    path: '/teams/new',
    name: 'NewTeam',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/TeamDetail.vue'),
  },
  {
    path: '/teams/:id',
    name: 'TeamDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/TeamDetail.vue'),
  },
  {
    path: '/debug',
    name: 'Debug',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Debug.vue'),
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
  },
  {
    path: '/companies/new',
    name: 'NewCompany',
    redirect: { name: 'NewCompanyDetailGeneral' },
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "companyDetail" */ '../views/CompanyDetail.vue'
      ),
    children: [
      {
        path: '',
        name: 'NewCompanyDetailGeneral',
        meta: {
          protected: true,
          index: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailGeneral.vue'
          ),
      },
    ],
  },
  {
    path: '/companies/:id',
    name: 'CompanyDetail',
    redirect: { name: 'CompanyDetailGeneral' },
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "companyDetail" */ '../views/CompanyDetail.vue'
      ),
    children: [
      {
        path: 'general',
        name: 'CompanyDetailGeneral',
        meta: {
          protected: true,
          index: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailGeneral.vue'
          ),
      },
      {
        path: 'users',
        name: 'CompanyDetailUsers',
        meta: {
          protected: true,
          index: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailUsers.vue'
          ),
      },
      {
        path: 'teams',
        name: 'CompanyDetailTeams',
        meta: {
          protected: true,
          index: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailTeams.vue'
          ),
      },
      {
        path: 'dashboards',
        name: 'CompanyDetailCustomizableUis',
        meta: {
          protected: true,
          index: 3,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailCustomizableUis.vue'
          ),
      },
      {
        path: 'integrations',
        name: 'CompanyDetailIntegrations',
        meta: {
          protected: true,
          index: 4,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailIntegrations.vue'
          ),
      },
      {
        path: 'kpis',
        name: 'CompanyDetailKpis',
        meta: {
          protected: true,
          index: 5,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailKpis.vue'
          ),
      },
      {
        path: 'kpi-details',
        name: 'CompanyDetailCustomizableUiKpiDetails',
        meta: {
          protected: true,
          index: 6,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailCustomizableUiKpiDetails.vue'
          ),
      },
      {
        path: 'nudge-details',
        name: 'CompanyDetailNudgeDetails',
        meta: {
          protected: true,
          index: 7,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyDetail" */ '../views/CompanyDetailNudgeDetails.vue'
          ),
      },
    ],
  },
  {
    path: '/integrations',
    name: 'Integrations',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrations" */ '../views/Integrations.vue'
      ),
  },
  {
    path: '/integrations/new',
    name: 'NewIntegration',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrations" */ '../views/CreateIntegration.vue'
      ),
  },
  {
    path: '/integrations/:id',
    name: 'IntegrationDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrationsdetail" */ '../views/IntegrationDetail.vue'
      ),
    children: [
      {
        path: '',
        name: 'IntegrationGeneral',
        meta: {
          protected: true,
          index: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegrationGeneral" */ '../components/integrations/IntegrationGeneral.vue'
          ),
      },
      {
        path: 'records',
        name: 'IntegrationRecords',
        meta: {
          protected: true,
          index: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegrationRecords" */ '../views/IntegrationRecords.vue'
          ),
      },
      {
        path: 'pipelines',
        name: 'IntegrationPipelines',
        meta: {
          protected: true,
          index: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegrationRecords" */ '../views/IntegrationPipelines.vue'
          ),
      },
      {
        path: 'sanity',
        name: 'IntegrationSanity',
        meta: {
          protected: true,
          index: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegrationRecords" */ '../views/IntegrationSanity.vue'
          ),
      },
    ],
  },
  {
    path: '/pipelines/new',
    name: 'NewPipeline',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "pipeline" */ '../components/pipelines/PipelineDetail.vue'
      ),
  },
  {
    path: '/pipelines/:id',
    name: 'PipelineDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "pipeline" */ '../components/pipelines/PipelineDetail.vue'
      ),
  },
  {
    path: '/integration-runs',
    name: 'IntegrationRuns',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrationruns" */ '../views/IntegrationRuns.vue'
      ),
  },
  {
    path: '/integration-runs/new',
    name: 'NewIntegrationRun',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrationrundetail" */ '../views/IntegrationRunDetail.vue'
      ),
  },
  {
    path: '/integration-runs/:id',
    name: 'IntegrationRunDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "integrationrundetail" */ '../views/IntegrationRunDetail.vue'
      ),
  },
  {
    path: '/invited/:token(.*)',
    name: 'Invited',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Invited" */ '../views/Invited.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/PasswordReset.vue'),
  },
  {
    path: '/reset',
    name: 'ExecutePasswordReset',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "resetexecute" */ '../views/ExecutePasswordReset.vue'
      ),
  },
  {
    path: '/:id/oauth-callback',
    name: 'OAuthCallback',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "oauthcallback" */ '../views/OAuthCallback.vue'
      ),
  },
  {
    path: '/issue-reports',
    name: 'IssueReports',
    meta: {
      protected: true,
    },
    component: () => import('../views/IssueReports.vue'),
  },
  {
    path: '/issue-reports/:id',
    name: 'IssueReportDetail',
    meta: {
      protected: true,
    },
    component: () => import('../views/IssueReportDetail.vue'),
  },
  {
    path: '/login-as',
    name: 'LoginAs',
    meta: {
      protected: true,
    },
    component: () => import('../views/LoginAs.vue'),
  },
  {
    path: '/translations',
    name: 'Translations',
    meta: {
      protected: true,
    },
    component: () => import('../views/Translations.vue'),
  },
];

const router: VueRouter = new VueRouter({
  routes,
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach(async (to: Route, from: Route, next: any) => {
  if (!to.meta.protected) {
    return next();
  }
  try {
    await store.dispatch('auth/fetchActiveSession');
  } catch (e) {
    console.log(e);
  }
  const currentSession = await store.getters['auth/currentLogin'];
  if (Object.keys(currentSession).length === 0) {
    return next({ name: 'Login' });
  }
  try {
    await store.dispatch('users/fetchLoggedInUser');
    await store.dispatch('customizableUIs/fetchForLoggedInUser');
  } catch (e) {
    const error: AxiosError = e;
    if (error.response?.status === 401) {
      window.location.href = '/v1/#/';
    }
  }

  const user: User = store.getters['users/loggedInUser'];
  if (
    !user.isSuperAdmin &&
    !user.isAdmin &&
    !user.isManager &&
    user.isEmployee &&
    to.name !== 'EmployeeDashboard'
  ) {
    return next({ name: 'EmployeeDashboard' });
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({
      name: to.name,
      params: to.params,
      query: {
        start: from.query.start,
        stop: from.query.stop,
        interval: from.query.interval,
        ...to.query,
      },
    });
  } else {
    return next();
  }
});

export default router;
