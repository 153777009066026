import { DateRange } from '@/components/DateRangeSelect.vue';
import axios from '@/plugins/axios';
import { DateTime } from 'luxon';
import BaseService from '../base-service';
import { CustomizableUIComponent } from '../customizable-uis/customizable-ui.model';
import { KpiTopValueResult } from './kpi-top-value-result.model';
import { UiComponentType } from '@/shared/util/import-dashboard-components.util';

export default class KpiTopValuesService extends BaseService<KpiTopValueResult> {
  get endpoint() {
    return 'kpi-top-values';
  }

  async fetchKpiValuesForComponent(
    component: CustomizableUIComponent,
    dateRange: DateRange,
    teams?: string[],
    users?: string[],
    managers?: string[],
  ) {
    const kpi: string = component.kpi;
    let url = `${this.endpoint}/${kpi}`;
    if (component.type === UiComponentType.TopResultsCard) {
      url = `${this.endpoint}/${kpi}?number=${component.maxResultsTopCard}`;
    }
    const response = await axios.get<any>(url, {
      params: {
        start: this.calculateStartDate(dateRange, component.isYTD),
        stop: this.calculateStopDate(dateRange, component.isYTD),
        team: teams,
        user: users,
        manager: managers,
      },
    });

    return response.data;
  }

  private calculateStartDate(dateRange: DateRange, isYTD = false): string {
    if (isYTD) {
      return DateTime.fromISO(dateRange.start)
        .startOf('year')
        .toISO({ includeOffset: false });
    }
    return DateTime.fromISO(dateRange.start)
      .startOf(dateRange.interval as any)
      .toISO({ includeOffset: false });
  }

  private calculateStopDate(dateRange: DateRange, isYTD = false): string {
    if (isYTD) {
      return DateTime.fromISO(dateRange.stop)
        .endOf('day')
        .toISO({ includeOffset: false });
    }
    return DateTime.fromISO(dateRange.start)
      .endOf(dateRange.interval as any)
      .toISO({ includeOffset: false });
  }
}
