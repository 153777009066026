
import { CustomizableUIComponent } from '@/api/customizable-uis/customizable-ui.model';
import KpiValuesService from '@/api/kpi-values/kpi-values.service';
import Vue from 'vue';
import Component from 'vue-class-component';

import { KpiValueResult } from '@/api/kpi-values/kpi-value-result.model';
import { User } from '@/api/users/user.model';
import { Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '../DateRangeSelect.vue';
import UserTeamPerformanceColumn from '../UserTeamPerformanceColumn.vue';
import KpiValueBadgeColumn from '../KpiValueBadgeColumn.vue';
import { DateTime } from 'luxon';
import { Team } from '@/api/teams/team.model';
import _ from 'lodash';
import { DateRangeEnum } from '@/components/DateRangeSelect.vue';
import { DateObjectUnits } from 'luxon/src/datetime';

const kpiValuesService = new KpiValuesService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { UserTeamPerformanceColumn, KpiValueBadgeColumn },
})
export default class DealSourcePerTeamPerformanceCard extends Vue {
  isLoading = false;
  teamResults: KpiValueResult[] = [];
  teamIntervalResults: KpiValueResult[] = [];
  removedUsers: User[] = [];
  lastIntervalRange: DateRange = new DateRange();

  @Prop({ required: false })
  dateRange!: DateRange;

  @Prop({
    required: true,
  })
  uiComponent!: CustomizableUIComponent;

  @Prop()
  company!: string;

  @Prop()
  teams!: Team[];

  @Prop()
  managers!: string[];

  @Prop()
  employees!: User[];

  get team() {
    return this.teams?.map((e) => e._id);
  }

  get workingEmployees() {
    return this.removeUsers();
  }

  get title() {
    return this.uiComponent?.title;
  }

  get compareToLastInterval() {
    return this.uiComponent?.compareToLastInterval;
  }

  get dateRangeWindow() {
    switch (this.dateRange.interval) {
      case DateRangeEnum.Week:
        return 'WW';
      case DateRangeEnum.Month:
        return 'MM';
      case DateRangeEnum.Quarter:
        return 'q';
      default:
        return 'WW';
    }
  }

  async setDateTimeObject(
    interval: DateRangeEnum,
    intervalNumber: number,
    startDt: any,
  ) {
    const dateTimeObject: DateObjectUnits = {};
    switch (interval) {
      case DateRangeEnum.Week: {
        dateTimeObject.weekYear = Number(startDt.toFormat('y')) - 1;
        dateTimeObject.weekNumber = intervalNumber;
        break;
      }
      case DateRangeEnum.Month: {
        dateTimeObject.year = Number(startDt.toFormat('y')) - 1;
        dateTimeObject.month = intervalNumber;
        break;
      }
      default: {
        dateTimeObject.weekYear = Number(startDt.toFormat('y')) - 1;
        dateTimeObject.weekNumber = intervalNumber;
        break;
      }
    }
    return dateTimeObject;
  }

  async calculateNewDateRange(
    dateTimeObject: any,
    intervalNumber: number,
    startDt: any,
  ) {
    const dt = DateTime.fromObject(dateTimeObject);
    let compareStart;
    let compareStop;
    if (this.dateRange.interval !== DateRangeEnum.Quarter) {
      compareStart = dt
        .startOf(this.dateRange.interval)
        .toISO({ includeOffset: false });
      compareStop = dt
        .endOf(this.dateRange.interval)
        .toISO({ includeOffset: false });
    } else {
      compareStart = DateTime.fromFormat(intervalNumber.toString(), 'q').set({
        year: Number(startDt.toFormat('y')) - 1,
      });
      compareStop = compareStart
        .endOf('quarter')
        .toISO({ includeOffset: false });
      compareStart = compareStart.toISO({ includeOffset: false });
    }
    return {
      start: compareStart,
      stop: compareStop,
    };
  }

  async getLastIntervalDateRange() {
    const startDt = DateTime.fromISO(this.dateRange.start);
    const intervalNumber = Number(startDt.toFormat(this.dateRangeWindow));

    const dateTimeObject = await this.setDateTimeObject(
      this.dateRange.interval,
      intervalNumber,
      startDt,
    );
    const range = await this.calculateNewDateRange(
      dateTimeObject,
      intervalNumber,
      startDt,
    );
    this.lastIntervalRange.start = range.start;
    this.lastIntervalRange.stop = range.stop;
    this.lastIntervalRange.interval = this.dateRange.interval;
  }

  get kpis() {
    return this.uiComponent?.kpis;
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get tooltip() {
    return this.uiComponent?.tooltip;
  }

  get values() {
    return this.teamResults;
  }

  get compareValues() {
    return this.teamIntervalResults;
  }

  get employeeHoodyIds() {
    return this.workingEmployees?.map((e) => e.hoodyId);
  }

  checkIfUserExistsInDeletedUsers(user: User): boolean {
    if (!this.removedUsers || this.removedUsers.length === 0) return false;
    this.removedUsers.forEach((client) => {
      if (client.hoodyId === user.hoodyId) return true;
    });
  }

  checkForDuplicates(obj: any, list: any[]) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] === obj) return true;
    }
    return false;
  }

  removeUsers() {
    let newEmployees: User[] = _.cloneDeep(this.employees);
    if (!this.teams || this.teams.length === 0) return newEmployees;

    const team: Team = this.teams[0];
    team.members.forEach((member) => {
      if (!member.until) return;
      const start = DateTime.fromISO(this.dateRange.start);
      const until = DateTime.fromISO(member.until);
      if (start > until) {
        const index = newEmployees.findIndex(
          (e) => e.hoodyUser.id === member.hoodyUser,
        );
        const user = newEmployees[index];
        const isDuplicate = this.checkIfUserExistsInDeletedUsers(user);
        if (!isDuplicate) this.removedUsers.push(user);

        // remove user from employees list
        newEmployees.splice(index, 1);
      } else {
        if (this.removedUsers && this.removedUsers.length > 0) {
          this.removedUsers.forEach((user) => {
            const isDuplicate = this.checkForDuplicates(user, newEmployees);
            if (!isDuplicate) newEmployees.push(user);
          });
        }
      }
    });
    newEmployees = _.uniqBy(newEmployees, (e) => e.hoodyId);
    return newEmployees;
  }

  @Watch('employees', { deep: true })
  onEmployeesChanged() {
    this.loadData();
  }

  @Watch('dateRange', { immediate: true, deep: true })
  onDateRangeChanged() {
    this.loadData();
  }

  @Watch('teams', { immediate: true, deep: true })
  onTeamChanged() {
    this.loadData();
  }

  getKpiValue(user: User, kpi: string) {
    for (const kvr of this.values) {
      const result = kvr?.kpi_values?.find((kpiValue) => {
        return kpiValue.user_id === user?.hoodyId && kpiValue.kpi_id === kpi;
      });
      if (result) {
        return result;
      }
    }
  }

  getKpiValueCompare(user: User, kpi: string) {
    for (const kvr of this.compareValues) {
      const result = kvr?.kpi_values?.find((kpiValue) => {
        return kpiValue.user_id === user?.hoodyId && kpiValue.kpi_id === kpi;
      });
      if (result) {
        return result;
      }
    }
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.teamResults = await kpiValuesService.fetchKpiValuesForComponent(
        this.uiComponent,
        this.dateRange,
        [],
        this.employeeHoodyIds,
        this.managers,
      );

      if (this.uiComponent?.compareToLastInterval)
        await this.getLastIntervalDateRange();

      this.teamIntervalResults =
        await kpiValuesService.fetchKpiValuesForComponent(
          this.uiComponent,
          this.lastIntervalRange,
          [],
          this.employeeHoodyIds,
          this.managers,
        );
    } finally {
      this.workingEmployees;
      this.isLoading = false;
    }
  }
}
