export class Formatter {
  static currency(value: number) {
    return new Intl.NumberFormat('nl-BE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  }

  static percentage(value: number) {
    return `${Math.round(value * 100) / 100}%`;
  }

  static integer(value: number) {
    return Math.round(value);
  }

  static decimal(value: number) {
    return Math.round(value * 100) / 100;
  }

  static ratio(value: number, target: number) {
    return Formatter.decimal(value / target);
  }

  static ratioPercentage(value: number, target: number) {
    return Formatter.decimal(value / target);
  }

  static stringDecoder(value: string): string {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    return doc.documentElement.textContent;
  }

  static intToReadableString(num: any): string {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }
    const si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(3).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
      si[index].s
    );
  }
}
