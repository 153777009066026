export class SalesFunnel {
  pipelines: [
    {
      pipeline_id: string;
      funnel: {
        name: string;
        value: number;
      }[];
    },
  ];
}
