import Vue from 'vue';
import { IntegrationRun } from '@/api/integration-runs/integration-run.model';
import KpiRunsService from '@/api/integration-runs/integration-runs.service';
import { Filter } from '@/shared/types/filter.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const kpirunsService = new KpiRunsService();

const state = {
  current: new IntegrationRun(),
  all: [],
  pagination: {},
};

const getters = {
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, kpirun: IntegrationRun) {
    Vue.set(state, 'current', kpirun);
  },
  setAll(state: any, kpiruns: IntegrationRun[]) {
    Vue.set(state, 'all', kpiruns);
  },
};

const actions = {
  async fetchById(context: any, id: string) {
    try {
      const kpirun = await kpirunsService.fetchOne(id);
      context.commit('setCurrent', kpirun);
      return kpirun;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchAll(context: any, payload?: Filter): Promise<IntegrationRun[]> {
    const kpiruns = await kpirunsService.fetchAll(payload);
    context.commit('setAll', kpiruns);
    return kpiruns;
  },
  async delete(context: any, id: string): Promise<IntegrationRun> {
    try {
      return kpirunsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, kpirun: IntegrationRun): Promise<IntegrationRun> {
    try {
      return await kpirunsService.update(kpirun._id || '', kpirun);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, kpirun: IntegrationRun): Promise<IntegrationRun> {
    try {
      const result = await kpirunsService.create(kpirun);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
