var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-overlay',{staticClass:"h-100",attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"mb-0 h-100",attrs:{"body-class":"h-100"}},[(_vm.error)?_c('p',[_vm._v(_vm._s(_vm.error))]):_c('b-row',{staticClass:"align-items-center"},[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"text-uppercase text-muted no-wrap text-truncate"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('span',{staticClass:"h2 mb-0 header-title"},[_vm._v(" "+_vm._s(_vm.valueFormatted)+" ")]),(_vm.target)?_c('b-progress',{staticClass:"w-25 inline-progress ml-3",attrs:{"variant":"primary","value":_vm.value,"max":_vm.target,"height":"6px"}}):_vm._e()],1),(_vm.target)?_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.percentualProgress)+"% ")])]):_vm._e(),_c('b-col',{attrs:{"cols":"1","align-self":"start"}},[_c('b-row',[(
              !_vm.isLoading &&
              _vm.isYTD &&
              _vm.toolTipValue() &&
              _vm.toolTipValue().active.length > 0 &&
              _vm.toolTipValue().inActive
            )?_c('BIconInfoCircle',{staticClass:"m-1",attrs:{"id":`progress-bar-tooltip123-button-${_vm.uiComponent.kpi}`},on:{"click":() => (_vm.showPopover = !_vm.showPopover)}}):_vm._e(),_c('b-popover',{attrs:{"target":`progress-bar-tooltip123-button-${_vm.uiComponent.kpi}`,"placement":"bottom","custom-class":"wide-popover","triggers":"hover focus click","show":_vm.showPopover}},[[(
                  _vm.toolTipValue() &&
                  _vm.toolTipValue().active.length > 0 &&
                  _vm.toolTipValue().inActive
                )?_c('table',{staticClass:"table w-100"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('components.sideMenu.users'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('comp.amount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('value')))])])]),_c('tbody',[_vm._l((_vm.toolTipValue().active),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.amount))]),_c('td',[_vm._v(_vm._s(_vm.Formatter.currency(item.total)))])])}),_c('tr',[_c('td',[_c('strong',[_vm._v("Inactive Users: "+_vm._s(_vm.toolTipValue().inActive.usersAmount))])]),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.toolTipValue().inActive.usersAmount))])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.Formatter.currency( _vm.toolTipValue().inActive.dealTotalValue, )))])])])],2)]):_vm._e()]],2),(_vm.kpiInfoTranslations() !== '')?_c('BIconQuestionCircle',{staticClass:"m-1",attrs:{"id":`popover-bar-card-${_vm.uiComponent.kpi}`}}):_vm._e(),(_vm.kpiInfoTranslations() !== '')?_c('b-popover',{attrs:{"target":`popover-bar-card-${_vm.uiComponent.kpi}`,"placement":"bottom","triggers":"hover","content":_vm.kpiInfoTranslations()}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }