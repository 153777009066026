import Vue from 'vue';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import NudgesService from '@/api/nudges/nudges.service';
import { Filter } from '@/shared/types/filter.class';
import { Nudge } from '@/api/nudges/nudge.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const nudgesService = new NudgesService();

const state = {
  current: {},
  all: [],
};

const getters = {
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: any, user: any) {
    Vue.set(state, 'current', user);
  },
  setAll(state: any, nudges: PaginateResult<any>) {
    Vue.set(state, 'all', nudges.docs);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<PaginateResult<any>> {
    const nudges = await nudgesService.fetch(payload);
    context.commit('setAll', nudges);
    return nudges;
  },
  async create(context: any, nudge: Nudge): Promise<Nudge> {
    try {
      const result = await nudgesService.create(nudge);
      context.commit('setCurrent', result);
      return result;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async delete(context: any, id: string): Promise<Nudge> {
    try {
      return nudgesService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
