export class KpiTopValue {
  public id: string;
  public title: string;
  public url: string;
  public value: number;
  public reason_lost: string;
}

export class KpiTopValueResult {
  deals: KpiTopValue[] = [];
}
