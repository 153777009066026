import ColorHash from 'color-hash';

export class ColorUtils {
  private static colors = [
    '#33b6a5',
    '#e1bc29',
    '#ef7b45',
    '#503d42',
    '#cbc5ea',
    '#246eb9',
    '#2b3a67',
    '#ffc482',
    '#496a81',
  ];

  public static getUniqueColorForIndex = (
    index: number,
    userId: string,
  ): string => {
    if (index >= ColorUtils.colors.length) {
      const colorHash = new ColorHash();
      return colorHash.hex(userId);
    }
    return ColorUtils.colors[index];
  };
}
