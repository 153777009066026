import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { Company } from './company.model';

export default class CompaniesService extends BaseService<Company> {
  get endpoint() {
    return 'companies';
  }

  async reloadHoodyCompanyForCompany(id: string): Promise<Company> {
    const response = await axios.post<Company>(
      `${this.endpoint}/${id}/reload-from-hoody`,
    );
    return response.data;
  }
}
