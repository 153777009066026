import { BaseModel } from '@/shared/types/base.model';
import { Role } from '../auth/role.enum';
import { HoodyUser } from '../hoody-users/hoody-user.model';
import { UserMapping } from './user-mapping.model';

export class User extends BaseModel {
  constructor(
    public name?: string,
    public email?: string,
    public company?: any,
    public mapping: UserMapping[] = [],
    public isSuperAdmin: boolean = false,
    public isAdmin: boolean = false,
    public isManager: boolean = false,
    public isEmployee: boolean = true,
    public hoodyId?: string,
    public hoodyUser?: HoodyUser,
    public functionTitle?: string,
    public placeOfWork?: string,
  ) {
    super();
  }
}
