
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class StaleCompaniesKpiDetailsTable extends Vue {
  isLoading = true;
  @Prop()
  data!: any[];
}
