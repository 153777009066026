import moment from 'moment';
import * as _ from 'lodash';
import { Column } from '../types/column.class';
import { User } from '@/api/users/user.model';

export function formatDate(column: Column, obj: unknown) {
  return moment(_.get(obj, column.field)).format('ddd, DD-MM-YYYY HH:mm');
}

export function formatBoolean(column: Column, obj: unknown) {
  return _.get(obj, column.field) ? 'Yes' : 'No';
}

export function formatUser(column: Column, obj: unknown) {
  const user: User = _.get(obj, column.field);

  return user?.email || 'Unknown';
}

export function formatGeneralImpressionEmoji(
  generalImpression: GeneralImpressionScore,
): string {
  switch (generalImpression) {
    case '1':
      return '😡';
    case '2':
      return '😔';
    case '3':
      return '😐';
    case '4':
      return '😊';
    case '5':
      return '😎';
    default:
      return '❓';
  }
}

export function formatGeneralImpression(
  generalImpression: GeneralImpressionScore,
): string {
  switch (generalImpression) {
    case '1':
      return 'Insufficient';
    case '2':
      return 'Moderate';
    case '3':
      return 'As expected';
    case '4':
      return 'Good';
    case '5':
      return 'Excellent';
    default:
      return '❓';
  }
}

// TODO: Change this to use i18n
export function getGeneralImpressionExplanation(
  generalImpression: GeneralImpressionScore,
): string {
  switch (generalImpression) {
    case '1':
      return 'Not happy at all';
    case '2':
      return 'Not happy';
    case '3':
      return 'Neutral';
    case '4':
      return 'Happy';
    case '5':
      return 'Very happy';
    default:
      return 'No input available (yet)';
  }
}

export type GeneralImpressionScore =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | ''
  | undefined;
