import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/plugins/i18n.plugin';

import '@/plugins';
import '@/shared/util/import-dashboard-components.util';

import SideBar from './layouts/SideBar.vue';
import NoSidebar from './layouts/NoSideBar.vue';
Vue.component('sidebar-layout', SideBar);
Vue.component('no-sidebar-layout', NoSidebar);

// const req = require.context(
//   './components/dashboard-components',
//   true,
//   /\.(js|vue)$/i,
// );
// req.keys().map((key) => {
//   const name = key.match(/\w+/)[0];
//   console.log(name, req(key));
//   return Vue.component(name, req(key));
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
