
import {
  Bar as BarChartGenerator,
  Line as LineChartGenerator,
} from 'vue-chartjs/legacy';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  Filler,
  ChartOptions,
  LinearScale,
  BarElement,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
} from 'chart.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Formatter } from '@/shared/util/formatter.class';

ChartJS.register(
  LineElement,
  LineController,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Legend,
  Tooltip,
);

@Component({
  components: {
    BarChartGenerator,
    LineChartGenerator,
  },
})
export default class WeightedPipelineChart extends Vue {
  colors = [
    '#2C7BE5',
    '#45B1FF',
    '#F09A19',
    '#AE6900',
    '#33B6A5',
    '#00D97E',
    '#EAF0CE',
    '#C0A9B0',
  ];

  @Prop()
  values!: any[];

  @Prop()
  kpiValues!: any[];

  @Prop()
  labels!: string[];

  @Prop()
  kpiLabel!: string;

  get chartData(): ChartData {
    return {
      labels: this.labels,
      datasets: this.kpiDataset.concat(
        this.values.map((x, index) => {
          return {
            label: x.label,
            backgroundColor: this.colors[index % this.colors.length],
            data: x.values,
          };
        }),
      ),
    };
  }

  get kpiDataset(): any {
    if (!this.kpiValues || this.kpiValues.length === 0) {
      return [];
    }

    return [
      {
        type: 'line',
        yAxisID: 'yRight',
        label: this.kpiLabel,
        fill: false,
        data: this.kpiValues,
      },
    ];
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        grid: {
          display: true,
          drawBorder: false,
          borderDashOffset: 50,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        display: true,
        ticks: {},
        grid: {
          display: false,
        },
      },
      yRight: {
        position: 'right',
        // display: true,
        grid: {
          display: false,
          // drawBorder: false,
          // borderDashOffset: 50,
        },
        stacked: true,
      },
    },
    elements: {
      line: {
        fill: true,
        borderWidth: 2,
        borderColor: 'red',
        backgroundColor: '#2C7BE51A',
      },
      point: {
        radius: 1,
        hitRadius: 5,
        borderColor: 'red',
        backgroundColor: 'red',
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        callbacks: {
          label: function (tooltipItems: any) {
            if (tooltipItems.dataset.type === 'line') {
              return `${tooltipItems.dataset.label}: ${tooltipItems.raw}`;
            }
            const formattedValue = Formatter['currency'](tooltipItems.raw);
            return `${tooltipItems.dataset.label} ${formattedValue}`;
          },
        },
      },
    },
  };
}
