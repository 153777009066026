import CompaniesService from '@/api/companies/companies.service';
import { Company } from '@/api/companies/company.model';
import { Filter } from '@/shared/types/filter.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const companiesService = new CompaniesService();

interface CompanyState {
  current: Company;
  all: Company[];
}

const state: CompanyState = {
  current: new Company(),
  all: [],
};

const getters = {
  current: (state: CompanyState) => {
    return state.current;
  },
  all: (state: CompanyState) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: CompanyState, company: Company) {
    Vue.set(state, 'current', company);
  },
  setAll(state: CompanyState, companies: Company[]) {
    Vue.set(state, 'all', companies);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Company[]> {
    const companies = await companiesService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', companies);
    return companies;
  },
  async fetchById(context: any, id: string): Promise<Company> {
    const company = await companiesService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', company);
    return company;
  },
  async create(context: any, payload: Company): Promise<Company> {
    const company = await companiesService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', company);
    return company;
  },
  async updateById(context: any, payload: Company): Promise<Company> {
    const company = await companiesService
      .update(payload._id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', company);
    return company;
  },
  async deleteById(context: any, id: string): Promise<Company> {
    const company = await companiesService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', company);
    return company;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
