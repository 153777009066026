import sugar from './sugar.config';
import pipedrive from './pipedrive.config';
import teamleader from './teamleader.config';
import teamleader20 from './teamleader20.config';
import salesforce from './salesforce.config';

export const IntegrationTemplates = {
  SUGAR_CRM: sugar,
  PIPEDRIVE: pipedrive,
  TEAMLEADER: teamleader,
  TEAMLEADER20: teamleader20,
  SALESFORCE: salesforce,
};

export const AppConfig = {
  VUE_APP_API: process.env.VUE_APP_API,
  VUE_APP_HOODY_API: process.env.VUE_APP_HOODY_API,
  VUE_APP_TEAMLEADER_CLIENT_ID: process.env.VUE_APP_TEAMLEADER_CLIENT_ID,
};
