/* eslint-disable */
export default {
  name: '{{{name}}}',
  type: 'PIPEDRIVE',
  auth: {
    endpoint: 'https://{{{companyId}}}.pipedrive.com/api/v1/deals',
    body: { api_token: '{{{apiKey}}}' },
  },
  endpoints: [
    {
      type: 'CONTACT',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/persons',
      filter: [],
    },
    {
      type: 'ACTIVITIES',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/activities?user_id=0',
      filter: [],
    },
    {
      type: 'ACTIVITY_TYPES',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/activityTypes',
      filter: [],
    },
    {
      type: 'OPPORTUNITY',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/deals',
      filter: [],
    },
    {
      type: 'TASK_TYPE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/activityTypes',
      filter: [],
    },
    {
      type: 'USER',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/users',
      filter: [],
    },
    {
      type: 'COMPANY',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/organizations',
      filter: [],
    },
    {
      type: 'DEAL_PHASE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/stages',
      filter: [],
    },
    {
      type: 'DEAL_SOURCE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/leadSources',
      filter: [],
    },
    {
      type: 'NOTE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/notes',
      filter: [],
    },
    {
      type: 'ACTIVITY_TYPES',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/activityTypes',
      filter: [],
    },
    {
      type: 'PIPELINE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/pipelines',
      filter: [],
    },
    {
      type: 'STAGE',
      idField: 'id',
      url: 'https://{{{companyId}}}.pipedrive.com/api/v1/stages',
      filter: [],
    },
  ],
};
