import { KpiConfig } from '@/api/kpi-config/kpi-config.model';
import KpiConfigService from '@/api/kpi-config/kpi-config.service';
import { Filter } from '@/shared/types/filter.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

const kpiconfigService = new KpiConfigService();

interface KpiConfigState {
  current: KpiConfig;
  all: KpiConfig[];
}

const state: KpiConfigState = {
  current: new KpiConfig(),
  all: [],
};

const getters = {
  current: (state: KpiConfigState) => {
    return state.current;
  },
  all: (state: KpiConfigState) => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: KpiConfigState, kpiconfig: KpiConfig) {
    Vue.set(state, 'current', kpiconfig);
  },
  setAll(state: KpiConfigState, kpiconfig: KpiConfig[]) {
    Vue.set(state, 'all', kpiconfig);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<KpiConfig[]> {
    const kpiconfig = await kpiconfigService.fetchAll(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setAll', kpiconfig);
    return kpiconfig;
  },
  async fetchById(context: any, id: string): Promise<KpiConfig> {
    const kpiconfig = await kpiconfigService.fetchOne(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', kpiconfig);
    return kpiconfig;
  },
  async create(context: any, payload: KpiConfig): Promise<KpiConfig> {
    const kpiconfig = await kpiconfigService.create(payload).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', kpiconfig);
    return kpiconfig;
  },
  async updateById(context: any, payload: KpiConfig): Promise<KpiConfig> {
    const kpiconfig = await kpiconfigService
      .update(payload.id, payload)
      .catch((e) => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
        throw e;
      });
    context.commit('setCurrent', kpiconfig);
    return kpiconfig;
  },
  async deleteById(context: any, id: string): Promise<KpiConfig> {
    const kpiconfig = await kpiconfigService.delete(id).catch((e) => {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    });
    context.commit('setCurrent', kpiconfig);
    return kpiconfig;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
